<template>
  <div class="card">
    <validation-observer ref="VFormSebabKematian" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Form Penyebab Kematian</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <b-row>
              <b-col md="12">
                <b-table-simple responsive bordered class="table-sm">
                  <b-thead>
                    <b-tr>
                      <b-th style="width: 50%">Penyebab Kematian</b-th>
                      <b-th style="width: 50%"></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-1"
                          v-model="row.opt_1"
                          name="checkbox-1"
                          value="Y"
                          unchecked-value="N"
                        >
                          Penyakit atau kesadaran yang langsung mengakibatkan kematian
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Sebutkan nama penyakit</label>
                        <b-form-input v-model="row.nama_penyakit_1"></b-form-input>
                        <VValidate v-if="row.opt_1 == 'Y'" v-model="row.nama_penyakit_1" name="Nama Penyakit" rules="required" :vid="'nama_sakit' + 1" />
                      </b-form-group>
                      <b-form-group>
                        <label>Lama kira-kira mulai sakit hingga meninggal dunia</label>
                        <b-form-input v-model="row.lama_penyakit_1"></b-form-input>
                        <VValidate v-if="row.opt_1 == 'Y'" v-model="row.lama_penyakit_1" name="Lama Penyakit" rules="required" :vid="'lama_sakit' + 1" />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-2"
                          v-model="row.opt_2"
                          name="checkbox-2"
                          value="Y"
                          unchecked-value="N"
                        >
                        Penyakit-penyakit (bila ada) yang menjadi lantaran timbulnya sebab kematian tsb. Pada a, dengan menyebut penyakit yang menjadi pokok pangkal terakhir
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Sebutkan nama penyakit</label>
                        <b-form-input v-model="row.nama_penyakit_2"></b-form-input>
                        <VValidate v-if="row.opt_2 == 'Y'" v-model="row.nama_penyakit_2" name="Nama Penyakit" rules="required" :vid="'nama_sakit' + 2" />
                      </b-form-group>
                      <b-form-group>
                        <label>Lama kira-kira mulai sakit hingga meninggal dunia</label>
                        <b-form-input v-model="row.lama_penyakit_2"></b-form-input>
                        <VValidate v-if="row.opt_2 == 'Y'" v-model="row.lama_penyakit_2" name="Lama Penyakit" rules="required" :vid="'lama_sakit' + 2" />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th style="background-color: #eee" colspan="2">
                      <strong>Keterangan Khusus</strong>
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-3"
                          v-model="row.opt_rudapaksa"
                          name="checkbox-3"
                          value="Y"
                          unchecked-value="N"
                        >
                          Mati Karena Rudapaksa (Violent death)
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Macam Rudapaksa</label>
                        <v-select :options="mrJenisRudapaksa" v-model="row.jenis_rudapaksa"></v-select>
                        <VValidate v-if="row.opt_rudapaksa == 'Y'" v-model="row.jenis_rudapaksa" name="Macam Rudapaksa" rules="required" :vid="'macam_rudapaksa'" />
                      </b-form-group>
                      <b-form-group>
                        <label>Cara Kejadian Rudapaksa</label>
                        <b-form-input v-model="row.cara_kejadian_rudapaksa"></b-form-input>
                        <VValidate v-if="row.opt_rudapaksa == 'Y'" v-model="row.cara_kejadian_rudapaksa" name="Cara Kejadian Rudapaksa" rules="required" :vid="'kejadian_rudapaksa'" />
                      </b-form-group>
                      <b-form-group>
                        <label>Sifat jejas (kerusakan tubuh)</label>
                        <b-form-input v-model="row.sifat_jejas_rudapaksa"></b-form-input>
                        <VValidate v-if="row.opt_rudapaksa == 'Y'" v-model="row.sifat_jejas_rudapaksa" name="Sifat Jejas" rules="required" :vid="'sifat_jejas'" />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-4"
                          v-model="row.opt_kelahiran_mati"
                          name="checkbox-4"
                          value="Y"
                          unchecked-value="N"
                        >
                          Kelahiran Mati (Still birth)
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Apakah ini janin mati</label>
                        <b-row>
                          <b-col md="3">
                            <b-form-radio-group v-model="row.janin_mati" :options="yaTidakOptions" name="janin_mati"></b-form-radio-group>
                          </b-col>
                          <b-col md="8" v-if="row.janin_mati == 'Y'">
                            <b-form-input v-model="row.janin_mati_desc"></b-form-input>
                          </b-col>
                        </b-row>
                        <VValidate v-if="row.opt_kelahiran_mati == 'Y'" name="Opsi" v-model="row.janin_mati" rules="required" vid="janin_mati_opt"></VValidate>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-5"
                          v-model="row.opt_persalinan_kehamilan"
                          name="checkbox-5"
                          value="Y"
                          unchecked-value="N"
                        >
                          Persalinan Kehamilan
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Apakah ini peristiwa persalinan</label>
                        <b-row>
                          <b-col md="3">
                            <b-form-radio-group inline v-model="row.peristiwa_persalinan" :options="yaTidakOptions" name="peristiwa_persalinan"></b-form-radio-group>
                          </b-col>
                          <b-col md="8" v-if="row.peristiwa_persalinan == 'Y'">
                            <b-form-input placeholder="Sebab persalinan mati" v-model="row.peristiwa_persalinan_desc"></b-form-input>
                          </b-col>
                        </b-row>
                        <VValidate v-if="row.opt_persalinan_kehamilan == 'Y'" name="Opsi" v-model="row.peristiwa_persalinan" rules="required" vid="peristiwa_persalinan_opt"></VValidate>
                      </b-form-group>

                      <b-form-group>
                        <label>Apakah ini peristiwa kehamilan</label>
                        <b-row>
                          <b-col md="3">
                            <b-form-radio-group inline v-model="row.peristiwa_kehamilan" :options="yaTidakOptions" name="peristiwa_kehamilan"></b-form-radio-group>
                          </b-col>
                          <b-col md="8" v-if="row.peristiwa_kehamilan == 'Y'">
                            <b-form-input placeholder="Sebab kelahiran mati" v-model="row.peristiwa_kehamilan_desc"></b-form-input>
                          </b-col>
                        </b-row>
                        <VValidate v-if="row.opt_persalinan_kehamilan == 'Y'" name="Opsi" v-model="row.peristiwa_kehamilan" rules="required" vid="peristiwa_kehamilan_opt"></VValidate>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="v-middle">
                      <b-form-group>
                        <b-form-checkbox id="checkbox-6"
                          v-model="row.opt_operasi"
                          name="checkbox-6"
                          value="Y"
                          unchecked-value="N"
                        >
                          Operasi
                        </b-form-checkbox>
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group>
                        <label>Apakah disini dilakukan operasi</label>
                        <b-row>
                          <b-col md="3">
                            <b-form-radio-group inline v-model="row.operasi" :options="yaTidakOptions" name="operasi"></b-form-radio-group>
                          </b-col>
                          <b-col md="8" v-if="row.operasi == 'Y'">
                            <b-form-input placeholder="Jenis Operasi" v-model="row.operasi_desc"></b-form-input>
                          </b-col>
                        </b-row>
                        <VValidate v-if="row.opt_operasi == 'Y'" name="Opsi" v-model="row.operasi" rules="required" vid="operasi_opt"></VValidate>
                      </b-form-group>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row class="justify-content-end">
              <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
                <span>Kembali</span>
              </b-button>
              <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </b-button>
            </b-row>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
const _ = global._

export default {
  data(){
    return {
      loading: false,
      row: {},
      mrJenisRudapaksa: [],
      yaTidakOptions: [
        {text: 'Tidak', value: 'N'},
        {text: 'Ya', value: 'Y'},
      ]
    }
  },
  mounted(){
    this.GetInitRow()
  },
  methods: {
    hitSaveDraft(){

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft-form',
            payload: this.row,
            id_registrasi: this.$route.params.pageSlug,
            id: this.$route.params.id
          }
        }
      )
    },
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          
          this.hitSaveDraft().then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    doSubmit(){
      if((!this.row.opt_1 || this.row.opt_1 == 'N') && (!this.row.opt_2 || this.row.opt_2 == 'N')){
        return this.$swal({
          icon: 'warning',
          text: 'Mohon Pilih Salah Satu Penyebab Kematian!'
        })
      }

      this.$refs.VFormSebabKematian.validate().then(success => {
        if(!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                            inv.push(el[i].id)
                          }
                      }
                  },250)
              }
          })
        }

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$parent.loadingOverlay = true

            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: {
                  type: 'submit-dokumen',
                  type_dokumen: this.$route.params.type,
                  id: this.$route.params.id,
                  id_registrasi: this.$route.params.pageSlug,
                  payload: this.row
                }
              },
              "POST"
            ).then(res => {
              this.$parent.loadingOverlay = false

              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            }).catch(err => {
              console.log(err)

              this.$parent.loadingOverlay = false
              this.$swal({
                text: err.response.data.message || 'Terjadi suatu kesalahan!',
                icon: 'error'
              })
            })

          }
        })

      })
    },
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-doc-form", 
        {
          params: {
            id: this.$route.params.id
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>
<style>
  .v-middle {
    vertical-align: middle;
  }
</style>