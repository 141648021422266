<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen</label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
          <div :class="row.arfl_ttd == 'digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
              <div class="form-group">
                <label>Nama & Tanda Tangan Dokter Rumah Sakit Harapan</label>
                <b-form-input v-model="row.ttd_name"></b-form-input>
                <VValidate v-if="row.arfl_ttd == 'digital'" name="Nama Penanda Tangan" v-model="row.ttd_name" rules="required"></VValidate>
              </div>
              <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdSuratKematian">
                  <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdSuratKematian"
                  />
                  <VValidate v-if="row.arfl_ttd == 'digital'" name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                  <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdSuratKematian','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                  </button>
                  
                  <button @click.prevent="output('ttdSuratKematian','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="document-framer">
                <div class="df-content">
                  <div style="position: relative;max-width: 700px;">
                    <!-- Copas Template dari sini -->
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 13px;">
                          <tbody><tr>
                            <td>
                              <div style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                              </div>
                              <div style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                                <h1 style="margin:0;font-size: 16px;line-height: 20px;margin-bottom: 2px;">Rumah
                                  Sakit
                                  Harapan Magelang</h1>
                                <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                                <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                                <p style="margin:0;">Fax. (0293-364037)</p>
                              </div>
                            </td>
                            <td style="text-align: right;">
                              <span>Magelang, {{now}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="3" style="padding: 4px 8px;">
                              <h2 style="text-decoration: underline;font-size: 16px;text-transform: uppercase;padding-top: 16px;display: inline-block;">
                                Surat Keterangan Kematian</h2>
                            </th>
                          </tr>
                          <tr>
                            <td colspan="3" style="padding: 4px 8px;padding-left: 64px;">
                              <p style="text-indent: 64px;">Yang bertanda tangan dibawah ini, Dokter Rumah Sakit
                                Harapan
                                Magelang menyatakan bahwa seorang penderita:</p>
                              <div style="padding-left: 56px;">
                                <table style="border-collapse: collapse;font-size: 13px;">
                                  <tbody><tr>
                                    <td style="padding: 2px 8px">Nama</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.fullname}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Jenis Kelamin</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.gender == 1 ? 'Laki-Laki' : row.gender == 2 ? 'Perempuan' : 'Tidak Diketahui'}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Tempat, Tgl. Lahir</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.pob}}, {{row.dob | moment("DD MMMM YYYY")}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">NIK</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.nik}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Pekerjaan</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.pekerjaan_text}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Alamat</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Nomor Reg.</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.reg_code}}</td>
                                  </tr>
                                </tbody></table>
                              </div>
                              <p style="margin-top: 8px;">Orang tersebut telah dirawat dan telah meninggal dunia
                                di Rumah
                                Sakit Harapan Magelang pada:</p>
                              <div style="padding-left: 56px;">
                                <table style="border-collapse: collapse;font-size: 13px;">
                                  <tbody><tr>
                                    <td style="padding: 2px 8px">Hari</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.date | moment("dddd")}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Tanggal</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{row.date | moment("DD MMMM YYYY")}}</td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 2px 8px">Jam</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{ row.date | moment("HH:mm") }}</td>
                                  </tr>
                                </tbody></table>
                              </div>
                              <p style="text-indent: 64px;padding-bottom: 16px;">Surat keterangan ini dibuat
                                sesuai dengan
                                kenyataan untuk dapat dipergunakan sebagaimana mestinya.</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="vertical-align: bottom;font-size: 11px;">
                              <span>Keterangan:</span>
                              <ol style="margin: 0;padding-left: 12px;">
                                <li>Lb. Putih (Pasien)</li>
                                <li>Lb. Merah (Rekam Medis)</li>
                                <li>Lb. Hijau (Administrasi Ruangan)</li>
                              </ol>
                            </td>
                            <td style="text-align: center;">
                              <span>Dokter Rumah Sakit Harapan<br>Magelang</span><br>
                              <span v-if="row.arfl_ttd == 'digital'">
                                <img :src="row.arfl_ttd_digital ? row.arfl_ttd_digital : 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                <br/>
                              </span>
                              <span v-else>
                                <br><br><br><br>
                              </span>
                              <span v-if="row.ttd_name">({{ row.ttd_name }})</span>
                              <span v-else>(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span>
                            </td>
                            <td style="padding-left: 16px;vertical-align: bottom;font-size: 11px;">RM. 250</td>
                          </tr>
                        </tbody></table>
                      </div>
                    </div>
                    <!-- End Copas Template -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'

  const moment = require('moment')
  const _ = global._

  export default {
    props: {
      id_formulir: Number,
      mrTTD: Array
    },
    data(){
      return {
        loading: false,
        row: {}
      }
    },
    computed: {
      now(){
        return moment().format('DD MMM YYYY')
      },
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.row[field] = null
      },
      output(refs,field) {
        try{
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          console.log(isEmpty, data)
          if(!isEmpty){
            this.$set(this.row, field, data)
          }
        }catch(err){
          console.log(err)
        }
      },
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'surat-kematian',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          this.$set(this, 'row', res.data.row)

          setTimeout(()=>{
            if(this.$refs['ttdSuratKematian']){
              this.$refs['ttdSuratKematian'].fromDataURL(this.row.arfl_ttd_digital)
            }
          },1500)
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      hitSaveDraft(){
        this.row.arfl_template = document.querySelector('.document-framer').innerHTML
        this.row.id_formulir = this.id_formulir

        return Gen.apiRest(
          "/do/" + this.$route.name,
          {
            data: {
              type: 'save-draft',
              payload: this.row
            }
          }
        )
      },
      autoSave: _.debounce(function(){
        this.hitSaveDraft()
      }, 1000),
      saveDraft(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.hitSaveDraft()
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
          }
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              if(this.row.arfl_ttd == 'manual'){
                this.row.ttd_name = ''
                this.row.arfl_ttd_digital = ''
              }

              this.row.arfl_template = document.querySelector('.document-framer').innerHTML
              this.row.id_formulir = this.id_formulir

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    payload: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.$emit('successSubmit')
                })
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
      },
    },
    watch:{
      row: {
        handler(){
          this.autoSave()
        },
        deep: true
      }
    }
  }
</script>