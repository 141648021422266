<template>
  <div class="card">
    <validation-observer ref="VFormTFRS" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Transfer Antar Rumah Sakit</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <div class="row">
              <b-col md="4">
                <b-form-group>
                  <label>Dirujuk ke <span class="text-danger">*</span></label>
                  <v-select placeholder="Pilih Dokter" :options="mrJenisDokter" v-model="row.rujukan" label="label" :reduce="v => v.value"></v-select>
                  <VValidate name="Dirujuk ke" v-model="row.rujukan" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <label>Tujuan <span class="text-danger">*</span></label>
                  <v-select placeholder="Pilih Tujuan" :options="mrTujuan" v-model="row.tujuan" label="label" :reduce="v => v.value"></v-select>
                  <VValidate name="Tujuan" v-model="row.tujuan" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <label>Poliklinik <span class="text-danger">*</span></label>
                  <v-select placeholder="Pilih Poliklinik" :options="mrPoliklinik" v-model="row.poliklinik" label="label" :reduce="v => v.value"></v-select>
                  <VValidate name="Poliklinik" v-model="row.poliklinik" rules="required" />
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="5">
                <b-form-group>
                  <label>Fasilitas yang diberikan <span class="text-danger">*</span></label>
                  <b-form-radio-group id="fasilitas" v-model="row.fasilitas" :options="mrFasilitas" name="fasilitas"></b-form-radio-group>
                  <b-form-input placeholder="Lainnya" class="mt-2" v-if="row.fasilitas == 'lain'" v-model="row.fasilitas_other"></b-form-input>
  
                  <VValidate v-if="row.fasilitas == 'lain'" name="Fasilitas" v-model="row.fasilitas_other" rules="required" />
                  <VValidate v-else name="Fasilitas" v-model="row.fasilitas" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <label>Metode Pembayaran <span class="text-danger">*</span></label>
                  <b-form-radio-group id="metode_bayar" v-model="row.metode_bayar" :options="mrMetodePembayaran" name="metode_bayar"></b-form-radio-group>
                  <b-form-input placeholder="Lainnya" class="mt-2" v-if="row.metode_bayar == 'lain'" v-model="row.metode_bayar_other"></b-form-input>
  
                  <VValidate v-if="row.metode_bayar == 'lain'" name="Metode Pembayaran" v-model="row.metode_bayar_other" rules="required" />
                  <VValidate v-else name="Metode Pembayaran" v-model="row.metode_bayar" rules="required" />
                </b-form-group>
              </b-col>
            </div>
          </b-overlay>
        </div>
        <div class="card-footer">
          <b-row class="justify-content-end">
            <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
              <span>Kembali</span>
            </b-button>
            <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
              <b><i class="icon-paperplane"></i></b>
              <span>Kirimkan</span>
            </b-button>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
const _ = global._


export default {
  data(){
    return {
      loading: false,
      row: {},
      mrJenisDokter: [
        {label: 'Dokter Umum', value: 'dokter_umum'},
        {label: 'Dokter Spesialis', value: 'dokter_spesialis'}
      ],
      mrTujuan: [
        {label: 'UGD', value: 'ugd'},
        {label: 'ICU / PICU', value: 'icu_picu'},
        {label: 'Unit Penunjang', value: 'unit_penunjang'},
        {label: 'Poliklinik', value: 'poli_klinik'},
      ],
      mrPoliklinik: [
        {label: 'Internist', value: 'internist'},
        {label: 'Anak', value: 'anak'},
        {label: 'Kebidanan dan Kandungan', value: 'kebidanan'},
        {label: 'Bedah', value: 'bedah'},
      ],
      mrFasilitas: [
        {text: 'Kursi Roda', value: 'kursi_roda'},
        {text: 'Kereta Dorong', value: 'kereta_dorong'},
        {text: 'Ambulans', value: 'ambulans'},
        {text: 'Lainnya', value: 'lain'},
      ],
      mrMetodePembayaran: [
        {text: 'Pribadi / Keluarga', value: 'pribadi'},
        {text: 'Asuransi', value: 'asuransi'},
        {text: 'Lainnya', value: 'lain'}
      ]
    }
  },
  mounted(){
    this.GetInitRow()
  },
  methods: {
    doSubmit(){
      this.$refs.VFormTFRS.validate().then(success => {
        if(!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                            inv.push(el[i].id)
                          }
                      }
                  },250)
              }
          })
        }

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$parent.loadingOverlay = true

            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: {
                  type: 'submit-dokumen',
                  type_dokumen: this.$route.params.type,
                  id: this.$route.params.id,
                  id_registrasi: this.$route.params.pageSlug,
                  payload: this.row
                }
              },
              "POST"
            ).then(res => {
              this.$parent.loadingOverlay = false

              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            }).catch(err => {
              console.log(err)

              this.$parent.loadingOverlay = false
              this.$swal({
                text: err.response.data.message || 'Terjadi suatu kesalahan!',
                icon: 'error'
              })
            })

          }
        })

      })
    },
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-doc-form", 
        {
          params: {
            id: this.$route.params.id
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      }).atch(err => {
        this.loading = false
        console.log(err)
      })
    }
  }
}
</script>