<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
          <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <b-row v-if="row.arfl_ttd">
            <b-col md=4> 
              <div class="form-group">
                <label>Nama & Tanda Tangan Yang membuat pernyataan <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_pembuat_pernyataan"></b-form-input>
                <VValidate name="Nama Pembuat Pernyataan" v-model="row.nama_pembuat_pernyataan" rules="required" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <label>Tanda Tangan <span class="text-danger">*</span></label>
                <div class="signing-element" data-target-id="ttdSusuFormula">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSusuFormula"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSusuFormula','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSusuFormula','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md=8>
              <div class="document-framer">
                <div class="df-content">
                  <div style="position: relative;max-width: 700px;">
                    <!-- Copas Template dari sini -->
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                      <div style="padding: 24px;box-sizing: border-box;">
                        <table style="border-collapse: collapse;width: 100%;font-size: 12px;line-height: 1.5;">
                          <tbody><tr>
                            <td colspan="3" style="padding: 8px;border-bottom: 1px solid #000;">
                              <div>
                                <div style="width: 10%;float: left;line-height: 68px;box-sizing: border-box;padding-right: 16px;">
                                  <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                </div>
                                <div style="float: left;width: 90%;">
                                  <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 22px;margin-bottom: 2px;">
                                    Rumah Sakit Harapan</h2>
                                  <div>Jl. Panembahan Senopati No. 11 Magelang</div>
                                  <div>Telp. (0293) 364033 s/d 364035 Fax. (0293) 364037</div>
                                </div>
                                <div style="clear: both;"></div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="3" style="padding: 8px;padding-top: 16px;">
                              <h2 style="font-size: 16px;text-transform: uppercase;margin: 0;display: inline-block;">
                                SURAT
                                PERSETUJUAN PEMBERIAN SUSU FORMULA</h2>
                            </th>
                          </tr>
                          <tr>
                            <td colspan="3" style="padding: 4px 8px;padding-top: 8px;text-align: justify;">
                              <p style="margin-bottom: 8px;"><strong>Tanggal {{now}} </strong>
                              </p>
                              <p style="margin-bottom: 8px;">Yang bertandatangan di bawah ini:</p>
                              <table style="border-collapse: collapse;font-size: 12px;line-height: 1.5;margin-bottom: 24px;">
                                <tbody><tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">Nama</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{ row.nama_pembuat_pernyataan }}</td>
                                </tr>
                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">Alamat</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{row.alamat_pembuat_pernyataan}}</td>
                                </tr>
                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">No. Telp/HP</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{row.no_hp_pembuat_pernyataan}}</td>
                                </tr>
                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">Hubungan dengan Bayi</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{row.pembuat_pernyataan}}</td>
                                </tr>

                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">Nama Bayi</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{row.fullname}}</td>
                                </tr>
                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">No RM</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;">{{row.no_rm}}</td>
                                </tr>
                                <tr>
                                  <td style="vertical-align: top;padding: 2px 8px;">Di Rawat Ruang</td>
                                  <td style="vertical-align: top;">:</td>
                                  <td style="vertical-align: top;padding: 2px 8px;"><strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</td>
                                </tr>
                              </tbody></table>
                              <p style="margin-bottom: 8px;">Dengan ini menyatakan setuju untuk diberikan Susu
                                Formula
                                kepada Bayi tersebut di atas, dengan alasan:</p>
                              <table style="border-collapse: collapse;font-size: 12px;line-height: 1.5;margin-bottom: 24px;">
                                <tbody>
                                  <tr v-if="row.alasan_1 == 'Y'">
                                    <td style="vertical-align: top;padding: 2px 8px;"> <span>▢ Kontra Indikasi Mendapatkan ASI </span></td>
                                  </tr>
                                  <tr v-if="row.alasan_2 == 'Y'">
                                    <td style="vertical-align: top;padding: 2px 8px;"> <span>▢ Bayi Prematur / BBLR </span></td>
                                  </tr>
                                  <tr v-if="row.alasan_3 == 'Y'">
                                    <td style="vertical-align: top;padding: 2px 8px;"> <span>▢ Permintaan keluarga, alasan: {{row.alasan_3_desc}} </span></td>
                                  </tr>
                                  <tr v-if="row.alasan_4 == 'Y'">
                                    <td style="vertical-align: top;padding: 2px 8px;"> <span>▢ Lainnya, alasan: {{row.alasan_4_desc}} </span></td>
                                  </tr>
                                </tbody>
                              </table>
                              <p style="margin-bottom: 8px;">Demikian pernyataan ini saya buat dengan
                                sebenar-benarnya
                                penuh kesadaran dan tanpa paksaan dari pihak manapun. Dan apabila terjadi
                                hal-hal yang
                                tidak diinginkan saya akan menerima resikonya.</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;">
                              <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                <span>Yang membuat pernyataan </span> <br />
                                <img :src="row.arfl_ttd_digital" alt="signature" width="200">
                              </template>
                              <template v-else>
                                <div style="margin-bottom: 64px;">Yang membuat pernyataan </div>
                              </template>
                              <div v-if="row.nama_pembuat_pernyataan">({{ row.nama_pembuat_pernyataan }})</div>
                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </td>
                          </tr>
                        </tbody></table>
                      </div>
                    </div>
                    <!-- End Copas Template -->
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'

  const moment = require('moment')
  const _ = global._

  export default {
    props: {
      id_formulir: Number,
      mrTTD: Array
    },
    data(){
      return {
        loading: false,
        row: {},
        mrJenisKelamin: []
      }
    },
    computed: {
      now(){
        return moment().format('dddd DD MMMM, YYYY HH:mm')
      },
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.row[field] = null
      },
      output(refs,field) {
        try{
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          console.log(isEmpty, data)
          if(!isEmpty){
            this.$set(this.row, field, data)
          }
        }catch(err){
          console.log(err)
        }
      },
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'dnr',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          console.log(res)
          this.$set(this, 'row', res.data.row)
          
          setTimeout(() => {
            if(res.data.row.arfl_ttd_digital){
              this.$refs['ttdSusuFormula'].fromDataURL(res.data.row.arfl_ttd_digital)
            }
          }, 1500)
          
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      hitSaveDraft(){
        this.row.arfl_template = document.querySelector('.document-framer').innerHTML
        this.row.id_formulir = this.id_formulir

        return Gen.apiRest(
          "/do/" + this.$route.name,
          {
            data: {
              type: 'save-draft',
              payload: this.row
            }
          }
        )
      },
      autoSave: _.debounce(function(){
        this.hitSaveDraft()
      }, 1000),
      saveDraft(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.hitSaveDraft()
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
          }
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              this.row.arfl_template = document.querySelector('.document-framer').innerHTML
              this.row.id_formulir = this.id_formulir

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    payload: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.$emit('successSubmit')
                })
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
      },
    },
    watch:{
      row: {
        handler(){
          this.autoSave()
        },
        deep: true
      }
    }
  }
</script>