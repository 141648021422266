<template>
<div>
  <validation-observer ref="VFormTTD">
    <b-form @submit.prevent="doSubmit">
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info opsiTtd" id="opsiTtdManual">
            <div class="d-flex">
              <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
            </div>
          </div>
        </div>
      </div>
      <div :class="'row'" id="template-surat-rujukan">
        <div class="col-md-8 offset-2 mt-2">
          <div class="document-framer">
            <div style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 9px;">
              <div>
                <table style="border-collapse: collapse;width: 100%;">                  
                  <tr>
                    <td style="padding:0;">
                      <table style="border-collapse: collapse;width: 100%;">
                        <tr>
                          <td style="padding:0 12px 8px;">
                            <div style="float: left;width: 7%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                              <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                            </div>
                            <div style="text-transform: uppercase;float: left; text-align:center; width: 86%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                              <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                                Harapan Magelang</h1>
                              <p style="margin:0;font-size: 11px;">Jl.P Senopati No 11, Magelang 56123</p>
                            </div>
                            <div style="display: block;clear: both;"></div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" style="padding: 4px 0;border-top: 1px solid #000;border-bottom: 1px solid #000;">
                            <h2 style="margin:0;text-align: center;text-transform: uppercase;font-size: 14px;">Formulir Transfer Pasien Antar Rumah Sakit</h2>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>                  
                  <tr>
                    <td style="padding: 10px 16px 12px;">
                      <table border="0" style="border-collapse: collapse;width: 100%;">
                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="150"><strong style="text-transform: uppercase;">Tanggal</strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="1">
                            {{now}}
                          </td>
                        </tr>
                      </table>
                      <table border="0" style="border-collapse: collapse;width: 100%;">
                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="150"><strong style="text-transform: uppercase;">Nama Pasien </strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                            {{row.fullname||"-"}}
                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="150"><strong style="text-transform: uppercase;">Tanggal Lahir </strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">

                            <span v-if="row.dob">
                              {{row.dob | moment("DD MMM YYYY")}}
                            </span>
                            <span v-else> - </span>

                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="150"><strong style="text-transform: uppercase;">NO RM</strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                            {{row['no_rm']}}
                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="150"><strong style="text-transform: uppercase;">NIK </strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                            {{row['nik']}}
                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="96"><strong style="text-transform: uppercase;">Alamat Pasien </strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                            {{row['address']}}
                          </td>
                        </tr>

                        <tr>
                          <td style="vertical-align: top;padding: 4px 0;" width="96"><strong style="text-transform: uppercase;">Jenis Kelamin </strong></td>
                          <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                          <td style="vertical-align: middle; " colspan="3">
                            <div style="display: inline-block; margin-top: 5px;">
                              <div style="display: inline-block;text-align: center;margin-right:2px;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                <img v-if="row.gender == 1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                              </div>
                              <div style="display: inline-block;vertical-align: middle; margin-right: 5px">
                                Laki-Laki
                              </div>

                              <div style="display: inline-block;text-align: center;margin-right:2px;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                <img v-if="row.gender == 2" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                              </div>
                              <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                Perempuan
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding:0;">
                      <table style="border-collapse: collapse;width: 100%;" border="1">
                        <thead>
                          <tr style="background-color: #d3d3d3; text-align: center;">
                            <th colspan="2" style="text-align: center;padding: 2px 12px;">Ringkasan Pasien yang dilakukan
                              Transfer/Rujuk ke Rumah Sakit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th width="50%" style="padding: 5px 7px 5px 0;text-align:center">Dokter yang Merujuk<br>
                              {{row.dokter_name||"-"}}
                            </th>
                            <th width="50%" style="padding: 5px 7px 5px 0;text-align:center">Ke Rumah Sakit/Dokter Rujukan <br>
                              {{row.faskes_rujuk_sisrute_text||"-"}}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding:0;">
                      <table style="border-collapse: collapse;width: 100%;border-top:0;" border="1" cellpadding="10">
                        <tbody>
                          <tr>
                            <td width="50%" style="padding: 6px 12px 6px 12px;text-align:left">
                              <p class="mb-1">Staf yang kontak</p>
                              <p class="mb-1">Tanggal</p>
                              <p class="mb-3">Pukul</p>

                              <p class="mb-0">Tiba di Tempat Tujuan Pukul :</p>
                            </td>
                            <td width="50%" style="padding: 6px 12px 6px 12px;text-align:left">
                              <p class="mb-1">Staf yang Menerima kontak</p>
                              <div class="ml-5 mb-3">
                                <p class="mb-1">Tanggal</p>
                                <p>Pukul</p>
                              </div>

                              <p class="mb-0">Tiba di Tempat Tujuan Pukul :</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>

                <table border="0" style="border-collapse: collapse;width: 100%;">
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong style="text-transform: uppercase;">Alasan Rujuk </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.alasan_merujuk}}
                    </td>
                    <td width="20"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong style="text-transform: uppercase;">Diagnosa Medis </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{(row.diagnosa_primer||{}).text||"-"}}
                    </td>
                    <td width="20"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Saat dipindahkan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      <p>{{row.kondisi_pasien_dipindahkan||"-"}}</p>
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Tanda Vital </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3"><span style="margin-right:30px;">TD: {{row.diastolik||"-"}}/{{row.sistolik||"-"}}</span>
                      <span style="margin-right:30px;">SUHU: {{row.suhu||"-"}} C</span>
                      <span style="margin-right:30px;">NADI: {{row.nadi||"-"}}x permenit</span>
                      <span style="margin-right:30px;">RF: {{row.pernafasan||"-"}}x Permenit </span>
                      <span style="margin-right:30px;">SPO2: {{row.sp_o2||"-"}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;"> </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">

                      <span style="margin-right:30px;">GCS:
                        {{row.gcs_total||0}}
                        <span v-if="row.gcs_total >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                        <span v-else-if="row.gcs_total >= 12 && row.gcs_total <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                        <span v-else-if="row.gcs_total >= 10 && row.gcs_total <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                        <span v-else-if="row.gcs_total >= 7 && row.gcs_total <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                        <span v-else-if="row.gcs_total >= 5 && row.gcs_total <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                        <span v-else-if="row.gcs_total >= 4 && row.gcs_total <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                      </span>

                      <span style="margin-right:30px;">E:
                        {{row.response_mata||0}}
                      </span>
                      <span style="margin-right:30px;">M:
                        {{row.response_motorik||0}}
                      </span>
                      <span style="margin-right:30px;">V:
                        {{row.response_verbal||0}}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;"> </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      <span style="margin-right:30px;">Reflek Pupil: {{row.reflek_pupil||"-"}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;"> </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      <span style="margin-right:30px;">Reflek Cahaya: {{row.reflek_cahaya||"-"}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong style="text-transform: uppercase;">Alergi </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.alergi||"-"}}
                    </td>
                    <td width="20"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong style="text-transform: uppercase;">RIWAYAT PENYAKIT </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.riwayat_penyakit||"-"}}
                    </td>
                    <td width="20"></td>
                  </tr>
                </table>
                <hr style="margin: 16px 0;">
                <table border="0" style="border-collapse: collapse;width: 100%;">
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="250"><strong style="text-transform: uppercase;">Tindakan yang telah dilakukan</strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0 4px;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.tindakan||"-"}}
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="250"><strong style="text-transform: uppercase;">Pemeriksaan yang telah dilakukan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.pemeriksaan||"-"}}
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Pengobatan yang telah dilakukan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.pengobatan||"-"}}
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Peralatan Medis yang Terpasang </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="vertical-align: middle; " colspan="3">
                      <div style="display: inline-block; margin-top: 5px;">
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.peralatan_yang_terpasang != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Tidak Ada
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;"> </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                    <td style="vertical-align: middle; " colspan="3">
                      <div style="display: inline-block; margin-top: 5px; margin-right: 10px;">
                        <div style="display: inline-block;  text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.peralatan_yang_terpasang == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Ada
                        </div>
                      </div>

                      <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                        <div style="display: inline-block;text-align: center; border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="(row.medis_terpasang||[]).indexOf('Infus') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Infus
                        </div>
                      </div>

                      <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                        <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">

                          <img v-if="(row.medis_terpasang||[]).indexOf('NGT') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          NGT
                        </div>
                      </div>

                      <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                        <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">

                          <img v-if="(row.medis_terpasang||[]).indexOf('DC') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          DC
                        </div>
                      </div>

                      <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                        <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">

                          <img v-if="(row.medis_terpasang||[]).indexOf('Oksigen') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Oksigen
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Perawatan Pasien yang dibutuhkan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                      {{row.perawatan||"-"}}
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Persetujuan biaya RS rujukan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="vertical-align: middle; " colspan="3">
                      <div style="display: inline-block; margin-top: 5px;">
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Ada
                        </div>
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <span style="line-height: 16px;"></span>
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Tidak Ada
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Persetujuan resiko perjalanan </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="vertical-align: middle; " colspan="3">
                      <div style="display: inline-block; margin-top: 5px;">
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.persetujuan_risiko_jalan == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Ada
                        </div>
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.persetujuan_risiko_jalan != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Tidak Ada
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Kejadian klinis selama dilakukan transfer </strong></td>
                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                    <td style="vertical-align: middle; " colspan="3">
                      <div style="display: inline-block; margin-top: 5px;">
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.kejadian_transfer == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Ada
                        </div>
                        <div style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                          <img v-if="row.kejadian_transfer != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                        </div>
                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                          Tidak Ada <strong style="margin:0 4px;width: 120px;display: inline-block;border-bottom: 1px solid #000;">
                            {{row.kejadian_transfer == 'Y'?row.kejadian_transfer_text:''}}
                          </strong>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <hr style="margin: 16px 0;">
                <table style="width: 100%;text-align: center;margin-bottom: 8px;font-size: 12px;">
                  <tbody>
                    <tr>
                      <td>
                        <br />
                        <span>Keluarga</span><br />
                        <br />
                        <br />
                        <br />
                        <span>(. . . . . . . . . . . . . . . . . . . . . . .)</span>
                      </td>
                      <td>
                        <span>DPJP</span><br />
                        <img :src="globalComp.uploader(row.qr)" alt="signature" width="60" id="ttdPasien">
                        <br />
                        <span>({{row.dokter_name||"-"}})</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <br />
                        <span>Petugas Yang Merujuk</span><br />
                        <br />
                        <br />
                        <br />
                        <span>(. . . . . . . . . . . . . . . . . . . . . . .)</span>
                      </td>
                      <td>
                        <br />
                        <span>Penerima Rujukan</span><br />
                        <br />
                        <br />
                        <br />
                        <span>(. . . . . . . . . . . . . . . . . . . . . . .)</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="text-right">
            <button type="button" class="btn" @click="$emit('closeModal')" data-dismiss="modal">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </div>

    </b-form>
  </validation-observer>
</div>
</template>

<script>
import Gen from '@/libs/Gen.js'

const moment = require('moment')
const _ = global._

export default {
  props: {
    id_formulir: Number,
    mrTTD: Array
  },
  data() {
    return {
      loading: false,
      row: {
        diagnosa_primer: {},
      },
      rowReg: {},
      rowRujukan: {},
      mrJenisKelamin: []
    }
  },
  computed: {
    now() {
      return moment().format('dddd DD MMMM, YYYY HH:mm')
    },
    globalComp() {
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.GetPasienData()
  },
  methods: {
    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs, field) {
      try {
        let {
          isEmpty,
          data
        } = this.$refs[refs].saveSignature()
        console.log(isEmpty, data)
        if (!isEmpty) {
          this.$set(this.row, field, data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    GetPasienData() {
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$route.name + '/get-data', {
          params: {
            type: 'dnr',
            id_formulir: this.id_formulir,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        console.log(res)
        this.$set(this, 'row', res.data.row)

        setTimeout(() => {
          if (res.data.row.arfl_ttd_digital) {
            this.$refs['ttdSusuFormula'].fromDataURL(res.data.row.arfl_ttd_digital)
          }
        }, 1500)

      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    doSubmit() {
      this.$refs.VFormTTD.validate().then(success => {
        if (!success) return        

        this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if (result.value) {
              this.$emit('setLoadingOverlay', true)

              this.row.arfl_template = document.querySelector('#template-surat-rujukan').innerHTML
              this.row.id_formulir = this.id_formulir
              this.row.arfl_ttd = null

              Gen.apiRest(
                  "/do/" + this.$route.name, {
                    data: {
                      type: 'submit-ttd',
                      payload: this.row
                    }
                  }
                )
                .then(res => {
                  this.$emit('setLoadingOverlay', false)
                  this.$swal({
                    icon: 'success',
                    text: res.data.message,
                  }).then(() => {
                    this.$emit('successSubmit')
                  })
                })
                .catch(err => {
                  this.$emit('setLoadingOverlay', false)
                  console.log(err)
                  this.$swal({
                    icon: 'error',
                    text: 'Terjadi suatu kesalahan!'
                  })
                })

            }
          })

      })
    },
  },
  watch: {}
}
</script>
