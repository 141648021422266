<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen</label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'
  export default {
    props: {
      id_formulir: Number,
      mrTTD: Array
    },
    data(){
      return {
        loading: false,
        row: {}
      }
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'transfer-antar-rumah-sakit',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          console.log(res)
          this.$set(this, 'row', res.data.row)
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    paylaod: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                console.log(res)
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
              })


            }
          })

        })
      },
    }
  }
</script>