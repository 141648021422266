<template>
  <div class="card">
    <validation-observer ref="VFormPenolakanPengobatan" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Form Penolakan Pengobatan</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Penolakan Pengobatan <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.penolakan_pengobatan"></b-form-input>
                  <VValidate name="Penolakan Pengobatan" v-model="row.penolakan_pengobatan" rules="required" />
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="6">
                <b-form-group>
                  <label>Saya telah mendapat penjelasan tentang <span class="text-danger">*</span></label>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="row.penjelasan_1"
                    name="checkbox-1"
                    value="Y"
                    unchecked-value="N"
                  >
                    Hak saya untuk menolak atau tidak melanjutkan pengobatan pasien
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="row.penjelasan_2"
                    name="checkbox-2"
                    value="Y"
                    unchecked-value="N"
                  >
                    Tentang konsekuensi dari keputusan yang telah saya ambil
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-3"
                    v-model="row.penjelasan_3"
                    name="checkbox-3"
                    value="Y"
                    unchecked-value="N"
                  >
                    Tentang tanggung jawab saya dengan keputusan tersebut
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-4"
                    v-model="row.penjelasan_4"
                    name="checkbox-4"
                    value="Y"
                    unchecked-value="N"
                  >
                    Tersedianya alternatif pelayanan dan pengobatan pasien
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="checkbox-5"
                    v-model="row.penjelasan_5"
                    name="checkbox-5"
                    value="Y"
                    unchecked-value="N"
                  >
                    Lainnya
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="row.penjelasan_5 == 'Y'">
                  <b-form-input v-model="row.penjelasan_lainnya"></b-form-input>
                  <VValidate name="Keterangan" v-model="row.penjelasan_lainnya" rules="max:128"></VValidate>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="justify-content-end">
              <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
                <span>Kembali</span>
              </b-button>
              <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </b-button>
            </b-row>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
const _ = global._
export default {
  data(){
    return {
      loading:  false,
      row: {},
      mrPenjelasan: []
    }
  },
  mounted(){
    this.GetInitRow()
  },
  methods: {
    hitSaveDraft(){
      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft-form',
            payload: this.row,
            id_registrasi: this.$route.params.pageSlug,
            id: this.$route.params.id
          }
        }
      )
    },
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          
          this.hitSaveDraft().then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    doSubmit(){
      if(
        (!this.row.penjelasan_1 || this.row.penjelasan_1 == 'N') && 
        (!this.row.penjelasan_2 || this.row.penjelasan_2 == 'N') && 
        (!this.row.penjelasan_3 || this.row.penjelasan_3 == 'N') && 
        (!this.row.penjelasan_4 || this.row.penjelasan_4 == 'N') &&
        (!this.row.penjelasan_5 || this.row.penjelasan_5 == 'N')
        ){
          return this.$swal({
            icon: 'warning',
            text: 'Mohon pilih salah satu penjelasan!'
          })
        }
      this.$refs.VFormPenolakanPengobatan.validate().then(success => {
        if(!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                            inv.push(el[i].id)
                          }
                      }
                  },250)
              }
          })
        }

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$parent.loadingOverlay = true
            console.log(this.row)
            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: {
                  type: 'submit-dokumen',
                  type_dokumen: this.$route.params.type,
                  id: this.$route.params.id,
                  id_registrasi: this.$route.params.pageSlug,
                  payload: this.row
                }
              },
              "POST"
            ).then(res => {
              this.$parent.loadingOverlay = false

              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            }).catch(err => {
              console.log(err)

              this.$parent.loadingOverlay = false
              this.$swal({
                text: err.response.data.message || 'Terjadi suatu kesalahan!',
                icon: 'error'
              })
            })

          }
        })

      })
    },
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-doc-form", 
        {
          params: {
            id: this.$route.params.id
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    }
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>