<template>
  <div class="card">
    <validation-observer ref="VFormSuratKeteranganLahir" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Form Serah Terima Bayi</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                  <b-form-radio-group
                    id="ttd"
                    v-model="row.arfl_ttd"
                    :options="mrTTD"
                    name="ttd"
                  ></b-form-radio-group>
                  <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="row.arfl_ttd">
              <b-col md="4">
                <b-form-group>
                  <label>Nama Keluarga <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name"></b-form-input>
                  <VValidate name="Nama Ibu" v-model="row.ttd_name" rules="required" />
                </b-form-group>
                <b-form-group>
                  <label>NIK <span class="text-danger">*</span></label>
                  <b-form-input :formatter="nik" v-model="row.ttd_nik"></b-form-input>
                  <VValidate name="NIK" v-model="row.ttd_nik" rules="required" />
                </b-form-group>
                <b-form-group>
                  <label>Alamat Keluarga <span class="text-danger">*</span></label>
                  <b-form-textarea v-model="row.ttd_alamat"></b-form-textarea>
                  <VValidate name="Alamat Keluarga" v-model="row.ttd_alamat" rules="required" />
                </b-form-group>
                <b-form-group>
                  <label>No. Telp/Hp Keluarga<span class="text-danger">*</span></label>
                  <b-form-input :formatter="telp" v-model="row.ttd_no_hp"></b-form-input>
                  <VValidate name="No. Telp/Hp Keluarga" v-model="row.ttd_no_hp" rules="required" />
                </b-form-group>
                <b-form-group>
                  <label>Hubungan dengan Bayi<span class="text-danger">*</span></label>
                  <v-select name="Hubungan dengan Bayi" v-model="row.ttd_relasi" :options="mRelasi"></v-select>
                  <VValidate name="Hubungan dengan Bayi" v-model="row.ttd_relasi" rules="required" />
                </b-form-group>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Keluarga <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdKeluarga">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdKeluarga"
                    />
                    <VValidate name="Tanda Tangan Keluarga" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdKeluarga','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdKeluarga','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <label>Nama Pengenal Bayi <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.nama_pengenal_bayi"></b-form-input>
                  <VValidate name="Nama Pengenal Bayi" v-model="row.nama_pengenal_bayi" rules="required" />
                </b-form-group>
                <b-form-group>
                  <label>Nama & Tanda Tangan Bidan/Perawat/Saksi <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name_perawat"></b-form-input>
                  <VValidate name="Nama Bidan/Perawat/Saksi" v-model="row.ttd_name_perawat" rules="required" />
                </b-form-group>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <div class="signing-element" data-target-id="ttdPerawat">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdPerawat"
                    />
                    <VValidate name="Tanda Tangan Penolong" v-model="row.arfl_ttd_digital_perawat" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPerawat','arfl_ttd_digital_perawat')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdPerawat','arfl_ttd_digital_perawat')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;max-width: 720px;">
                      <!-- Copas Template dari sini -->
                      <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                        <div>
                          <table style="border-collapse: collapse;width: 100%;font-size: 12px;" border="1">
                            <tbody>
                              <tr>
                                <td colspan=2 style="padding:0 12px 8px;">
                                  <div style="vertical-align: middle; text-align: center;">
                                    <h1 style="text-transform: uppercase;margin:0;font-size: 12px;line-height: 1.5;margin-bottom: 2px;">
                                      Rumah Sakit
                                      Harapan Magelang</h1>
                                    <p style="margin:0;font-weight: bold;font-size: 12px;">Jl.P Senopati No 11,
                                      Magelang 56123
                                    </p>
                                    <p style="margin:0;font-weight: bold;font-size: 12px;">Telp. (0293)-364033 s/d
                                      364035 Fax.
                                      (0293-364037)
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;border: 1px solid #000;" width="50%">
                                  <div style="text-align: center;">
                                    <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;width: 56px;" alt="">
                                    <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">
                                      LEMBAR SERAH TERIMA BAYI</h2>
                                  </div>
                                </td>
                                <td style="padding: 4px 8px;border: 1px solid #000;" width="50%">
                                  <div style="text-transform: uppercase;">
                                    <div style="margin-bottom:4px;">Nama: {{row.fullname}}</div>
                                    <div style="margin-bottom:4px;">No. RM: {{row.no_rm}}</div>
                                    <div style="margin-bottom:4px;">Tgl. Lahir: {{row.dob | moment('DD MMMM YYYY')}}</div>
                                  </div>
                                </td>
                              </tr>
                            <tr>
                              <td colspan="2" style="padding: 4px 8px;padding-top: 8px;text-align: justify;">
                                <p style="margin-bottom: 8px;"><strong>Tanggal {{now}} </strong>
                                </p>
                                <p style="margin-bottom: 8px;">Yang bertandatangan di bawah ini:</p>
                                <table border="0" style="border-collapse: collapse;font-size: 12px;line-height: 1.5;margin-bottom: 24px;">
                                  <tbody><tr>
                                    <td style="vertical-align: top;padding: 2px 8px;">Nama</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="vertical-align: top;padding: 2px 8px;">{{ row.ttd_name }}</td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: top;padding: 2px 8px;">NIK</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="vertical-align: top;padding: 2px 8px;">{{row.ttd_nik}}</td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: top;padding: 2px 8px;">Alamat</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="vertical-align: top;padding: 2px 8px;">{{ row.ttd_alamat }}</td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: top;padding: 2px 8px;">No. Telp/HP</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="vertical-align: top;padding: 2px 8px;">{{row.ttd_no_hp}}</td>
                                  </tr>
                                  <tr>
                                    <td style="vertical-align: top;padding: 2px 8px;">Hubungan dengan Bayi</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="vertical-align: top;padding: 2px 8px;">{{row.ttd_relasi}}</td>
                                  </tr>
                                </tbody></table>
                                <p style="margin-bottom: 8px;">Saya Menyatakan pada saat saya pulang telah
                                  menerima bayi,
                                  saya memeriksa dan meyakinkan bahwa bayi tersebut adalah betul-betul anak
                                  saya.Saya
                                  mengecek No
                                  Penengnya dan Nama Pengenalnya adalah
                                  <span>{{ row.nama_pengenal_bayi }}</span> dan berisi keterangan pengenal yang
                                  sesuai.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;border-top: 0;">
                                <div style="text-align:center;">
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_perawat">
                                    <span>Tanda Tangan Bidan/Perawat/Saksi </span> <br />
                                    <img :src="row.arfl_ttd_digital_perawat" alt="signature" width="200">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 64px;">Tanda Tangan Bidan/Perawat/Saksi </div>
                                  </template>
                                  <div v-if="row.ttd_name_perawat">({{ row.ttd_name_perawat }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;border-top: 0;">
                                <div style="text-align:center;">
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                    <span>{{row.ttd_relasi}} </span> <br />
                                    <img :src="row.arfl_ttd_digital" alt="signature" width="200">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 64px;">{{row.ttd_relasi}} </div>
                                  </template>
                                  <div v-if="row.ttd_name">({{ row.ttd_name }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                            </tr>
                          </tbody></table>
                        </div>
                      </div>
                      <!-- End Copas Template -->
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-end">
              <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
                <span>Kembali</span>
              </b-button>
              <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </b-button>
            </b-row>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require("moment")
export default {
  props: {
    mrTTD: Array
  },
  data(){
    return { 
      loading: false,
      row: {},
      mRelasi: []
    }
  },
  computed: {
    now(){
      return moment().format('DD MMMM, YYYY HH:mm')
    }
  },
  mounted(){
    this.GetInitRow()
  },
  methods: {
    telp(e){
      return String(e).replace(/[^0-9]/g, "").replace(/[+,\-,\s]/g, '').substring(0, 18)
    },
    nik(e) {
      return String(e).replace(/[^0-9]/g, "").substring(0, 16)
    },
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-data", 
        {
          params: {
            type: this.$route.params.type,
            id_formulir: this.$route.params.id,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        setTimeout(() => {
          if(res.data.row.arfl_ttd_digital){
            this.$refs['ttdKeluarga'].fromDataURL(res.data.row.arfl_ttd_digital)
          }

          if(res.data.row.arfl_ttd_digital_perawat){
            this.$refs['ttdPerawat'].fromDataURL(res.data.row.arfl_ttd_digital_perawat)
          }
        }, 1500)

      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.row[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        console.log(isEmpty, data)
        if(!isEmpty){
          this.$set(this.row, field, data)
        }
      }catch(err){
        console.log(err)
      }
    },
    hitSaveDraft(){
      if(this.row.arfl_ttd == 'manual'){
        this.row.ttd_name = ''
        this.row.arfl_ttd_digital = ''
      }

      this.row.arfl_template = document.querySelector('.document-framer').innerHTML
      this.row.id_formulir = this.$route.params.id

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft',
            payload: this.row
          }
        }
      )
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          this.hitSaveDraft()
          .then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    doSubmit(){
      this.$refs.VFormSuratKeteranganLahir.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.row.arfl_template = document.querySelector('.document-framer').innerHTML
            this.row.id_formulir = this.$route.params.id

            Gen.apiRest(
              "/do/" + this.$route.name,
              {
                data: {
                  type: 'submit-ttd',
                  payload: this.row
                }
              }
            )
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>