<template>
  <div class="card">
    <validation-observer ref="VFormSuratKeteranganLahir" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Form Surat Kematian</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Penandatanganan Dokumen <span class="text-danger">*</span> </label>
                  <b-form-radio-group
                    id="ttd"
                    v-model="row.arfl_ttd"
                    :options="mrTTD"
                    name="ttd"
                  ></b-form-radio-group>
                  <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="row.arfl_ttd">
              <b-col md="3">
                <b-form-group>
                  <label>Tanggal Kematian Pasien <span class="text-danger">*</span></label>
                  <datepicker v-model="row.tanggal_kematian" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                  <VValidate name="Tanggal Kematian Pasien" v-model="row.tanggal_kematian" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Waktu Kematian Pasien <span class="text-danger">*</span></label>
                  <vue-timepicker manual-input v-model="row.jam_kematian" format="HH:mm"></vue-timepicker>
                  <VValidate name="Waktu Kematian Pasien" v-model="row.jam_kematian" rules="required" />
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="4">
                <b-form-group>
                  <label>Nama Petugas <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name"></b-form-input>
                  <VValidate name="Nama Petugas" v-model="row.ttd_name" rules="required" />
                </b-form-group>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Petugas <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdPenolong">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdPenolong"
                    />
                    <VValidate name="Tanda Tangan Penolong" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPenolong','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdPenolong','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="height: 100%; position: relative; font-family: serif; color: rgb(51, 51, 51); line-height: 1.5; font-size: 12px;">
                      <div>
                        <table style="border-collapse: collapse; width: 100%; font-size: 13px;">
                          <tbody>
                            <tr>
                              <td>
                                <div style="float: left; width: 10%; vertical-align: middle; box-sizing: border-box; padding-right: 8px;">
                                  <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                </div>
                                <div style="text-transform: uppercase; float: left; width: 90%; vertical-align: middle; box-sizing: border-box; padding-left: 8px;">
                                  <h1 style="margin: 0px 0px 2px; font-size: 16px; line-height: 20px;">Rumah Sakit Harapan Magelang</h1>
                                  <p style="margin: 0px;">Jl.P Senopati No 11, Magelang 56123</p>
                                  <p style="margin: 0px;">Telp. (0293)-364033 s/d 364035</p>
                                  <p style="margin: 0px;">Fax. (0293-364037)</p>
                                </div>
                              </td>
                              <td style="text-align: right;">
                                <span>Magelang, {{now}}</span>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" style="padding: 4px 8px;" class="text-center">
                                <h2 style="text-decoration: underline; font-size: 16px; text-transform: uppercase; padding-top: 16px; display: inline-block;">Surat Keterangan Kematian</h2>
                                <p class='no_surat'>{{ row.no_surat }}</p>
                              </th>
                            </tr>
                            <tr>
                              <td colspan="3" style="padding: 4px 8px 4px 64px;">
                                <p style="text-indent: 64px;">Yang bertanda tangan dibawah ini, Dokter Rumah Sakit Harapan Magelang menyatakan bahwa seorang penderita:</p>
                                <div style="padding-left: 56px;">
                                  <table style="border-collapse: collapse; font-size: 13px;">
                                    <tbody>
                                      <tr>
                                        <td style="padding: 2px 8px;">Nama</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{row.fullname}}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Jenis Kelamin</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{row.gender == 1 ? 'Laki-Laki' : row.gender == 2 ? 'Perempuan' : 'Tidak Diketahui'}}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Tempat, Tgl. Lahir</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{ row.pob }}, {{row.dob | moment('DD MMMM YYYY')}}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">NIK</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{row.nik}}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Pekerjaan</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{row.pekerjaan_text}}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Alamat</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Nomor RM.</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">{{row.no_rm}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <p style="margin-top: 8px;">Orang tersebut telah dirawat dan telah meninggal dunia di Rumah Sakit Harapan Magelang pada:</p>
                                <div style="padding-left: 56px;">
                                  <table style="border-collapse: collapse; font-size: 13px;">
                                    <tbody>
                                      <tr>
                                        <td style="padding: 2px 8px;">Hari</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">
                                          <span> {{row.tanggal_kematian | moment('dddd')}} </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Tanggal</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;">
                                          <span> {{row.tanggal_kematian | moment('DD MMMM YYYY')}} </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 2px 8px;">Jam</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px;" v-if="row.jam_kematian">{{row.jam_kematian}}</td>
                                        <td style="padding: 2px 8px;" v-else>-</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <p style="text-indent: 64px; padding-bottom: 16px;">Surat keterangan ini dibuat sesuai dengan kenyataan untuk dapat dipergunakan sebagaimana mestinya.</p>
                              </td>
                            </tr>
                            <tr>
                              <td style="vertical-align: bottom; font-size: 11px;"></td>
                              <td style="text-align: center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                  <span>Dokter Rumah Sakit Harapan <br>Magelang </span> <br/>
                                  <img :src="row.arfl_ttd_digital" alt="signature" width="200" id="ttdPasien">
                                  <br />
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 48px;">
                                    <span>Dokter Rumah Sakit Harapan <br>Magelang </span>
                                  </div>
                                </template>
                                <div v-if="row.ttd_name">({{ row.ttd_name }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </td>
                              <td style="padding-left: 16px; vertical-align: bottom; font-size: 11px;"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-end mt-3">
              <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
                <span>Kembali</span>
              </b-button>
              <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </b-button>
            </b-row>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

const _ = global._
const moment = require('moment')
export default {
  components: {
    Datepicker,
    VueTimepicker
  },
  props: {
    mrTTD: Array
  },
  data(){
    return { 
      loading: false,
      row: {}
    }
  },
  mounted(){
    this.GetInitRow()
  },
  computed: {
    now(){
        return moment().format('DD MMM YYYY')
      },
  },
  methods: {
    hitSaveDraft(){
      this.row.arfl_template = document.querySelector('.document-framer').innerHTML
      this.row.id_formulir = this.$route.params.id

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft',
            payload: this.row
          }
        }
      )
    },
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-data", 
        {
          params: {
            type: this.$route.params.type,
            id_formulir: this.$route.params.id,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        setTimeout(() => {
          if(res.data.row.arfl_ttd_digital){
            console.log('TEST')
            this.$refs['ttdPenolong'].fromDataURL(res.data.row.arfl_ttd_digital)
          }
        }, 1500)
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.row[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        console.log(isEmpty, data)
        if(!isEmpty){
          this.$set(this.row, field, data)
        }
      }catch(err){
        console.log(err)
      }
    },
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          
          this.hitSaveDraft().then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    doSubmit(){
      this.$refs.VFormSuratKeteranganLahir.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.row.arfl_template = document.querySelector('.document-framer').innerHTML
            this.row.id_formulir = this.$route.params.id

            Gen.apiRest(
              "/do/" + this.$route.name,
              {
                data: {
                  type: 'submit-ttd',
                  payload: this.row
                }
              }
            )
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000)
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>