<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
          <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <b-row v-if="row.arfl_ttd">
            <template v-if="row.dnr == 'pasien'">
              <b-col md="4">
                
                <div class="form-group">
                  <label>Nama Saksi <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name_saksi"></b-form-input>
                  <VValidate name="Nama Saksi" v-model="row.ttd_name_saksi" rules="required"></VValidate>
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Saksi <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRSaksi">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRSaksi"
                    />
                    <VValidate name="Tanda Tangan Saksi" v-model="row.arfl_ttd_digital_saksi" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Nama Perawat <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name_perawat"></b-form-input>
                  <VValidate name="Nama Perawat" v-model="row.ttd_name_perawat" rules="required"></VValidate>
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Perawat <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRPerawat">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRPerawat"
                    />
                    <VValidate name="Tanda Tangan Perawat" v-model="row.arfl_ttd_digital_perawat" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRPerawat','arfl_ttd_digital_perawat')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRPerawat','arfl_ttd_digital_perawat')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Pasien <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRPasien">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRPasien"
                    />
                    <VValidate name="Tanda Tangan Pasien"  v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRPasien','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRPasien','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <div class="document-framer">
                  <div class="df-content">
                  <div
                    style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                    <div>
                      <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;width: 56px;" alt="">
                                <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{row.no_rm||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{row.nama_dokter||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{row.fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>NIK: <strong>{{row.nik||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="row.dob">{{ row.dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Jenis Kelamin: <strong>
                            {{row.gender == 1 ? 'L' : row.gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{row.reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</span></td>
                          </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="3">
                            <div style="text-transform: uppercase;text-align: center;">
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                              <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini saya:</p>
                            <table border="0" style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5;">
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Nama</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{row.fullname||"-"}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{row.dob|moment("DD MMMM YYYY")}}</td>
                              </tr>
                            </table>
                            <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan do not resuscitate (jangan diresusitasi).</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung saya berhenti berdetak atau jika saya berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                            <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah saya menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                            <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_saksi">
                                <span>Saksi</span> <br />
                                <img :src="row.arfl_ttd_digital_saksi" alt="signature" width="200">
                              </template>
                              <template v-else>
                                <div style="margin-bottom: 64px;">Saksi</div>
                              </template>
                              <div v-if="row.ttd_name_saksi">({{ row.ttd_name_saksi }})</div>
                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </div>
                          </td>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                <span>Magelang, {{now}} </span> <br />
                                <img :src="row.arfl_ttd_digital" alt="signature" width="200">
                              </template>
                              <template v-else>
                                <div style="margin-bottom: 64px;">Magelang, {{now}} </div>
                              </template>
                              <div v-if="row.fullname">({{ row.fullname }})</div>
                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <div style="text-align:center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_perawat">
                                  <span>Perawat </span> <br />
                                  <img :src="row.arfl_ttd_digital_perawat" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">Perawat </div>
                                </template>
                                <div v-if="row.ttd_name_perawat">({{ row.ttd_name_perawat }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  </div>
                </div>
              </b-col>
            </template>
            <template v-else-if="row.dnr == 'keluarga'">
              <b-col md="4">
                <div class="form-group">
                  <label>Nama Keluarga <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name"></b-form-input>
                  <VValidate name="Nama Keluarga" v-model="row.ttd_name" rules="required" />
                </div>
                <div class="form-group">
                  <label>Tanggal Lahir <span class="text-danger">*</span></label>
                  <datepicker v-model="row.ttd_dob" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                  <VValidate name="Tanggal Lahir" v-model="row.ttd_dob" rules="required" />
                </div>
                <div class="form-group">
                  <label>Jenis Kelamin <span class="text-danger">*</span></label>
                  <b-form-radio-group name="jenis_kelamin" :options="mrJenisKelamin" v-model="row.ttd_jk"></b-form-radio-group>
                  <VValidate name="Jenis Kelamin" v-model="row.ttd_jk" rules="required"></VValidate>
                </div>
                <div class="form-group">
                  <label>Alamat <span class="text-danger">*</span></label>
                  <b-form-textarea v-model="row.ttd_alamat"></b-form-textarea>
                  <VValidate name="Alamat" v-model="row.ttd_alamat" rules="required" />
                </div>
                <div class="form-group">
                  <label>Untuk <span class="text-danger">*</span></label>
                  <v-select v-model="row.ttd_untuk" :options="mrUntuk" :clearable="false" />
                  <template v-if="row.ttd_untuk == 'Lainnya'">
                    <b-form-input class="mt-1" v-model="row.ttd_untuk_lainnya"></b-form-input>
                    <VValidate name="Untuk" :vid="'untuklainnya'" v-model="row.ttd_untuk_lainnya" rules="required" />
                  </template>
                  <VValidate name="Untuk" :vid="'untuk'" v-model="row.ttd_untuk" rules="required" />
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Keluarga <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNR">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNR"
                    />
                    <VValidate name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNR','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNR','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Nama Saksi <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name_saksi"></b-form-input>
                  <VValidate name="Nama Saksi" v-model="row.ttd_name_saksi" rules="required" />
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Saksi <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRSaksi">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRSaksi"
                    />
                    <VValidate name="Tanda Tangan Saksi" v-model="row.arfl_ttd_digital_saksi" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                            <tr>
                              <td style="padding: 4px 8px;" width="20%" rowspan="4">
                                <div style="text-align: center;">
                                  <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;width: 56px;" alt="">
                                  <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Rumah Sakit Harapan</h2>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="40%"><span>No. RM: <strong>{{row.no_rm||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{row.nama_dokter||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{row.fullname||"-"}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>NIK: <strong>{{row.nik||"-"}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                              <strong v-if="row.dob">{{ row.dob | moment("DD MMMM YYYY")}}</strong>
                              <span v-else> - </span>
                              </span></td>
                              <td style="padding: 4px 8px;"><span>Jenis Kelamin: 
                              <strong>
                              {{row.gender == 1 ? 'L' : row.gender == 2 ? 'P' : 'TK'}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{row.reg_date | moment("DD MMMM YYYY, HH:mm")}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>Ruang: <strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</span></td>
                            </tr>
                          <tr>
                            <td style="padding: 4px 8px;" colspan="3">
                              <div style="text-transform: uppercase;text-align: center;">
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;">DNR (Do Not Resuscitate)</h3>
                                <h3 style="margin: 0;font-size: 18.62px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">Surat Pernyataan Jangan Dilakukan Resusitasi</h3>
                              </div>
                            </td>
                          </tr>
                        </table>

                        
                        <table border="1" style="border: 1px solid; border-collapse: collapse;width: 100%;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="3">
                              <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini:</p>
                              <table border="0" style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5; width: 100%;">
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.ttd_name||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="row.ttd_dob">{{ row.ttd_dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Umur/Jenis Kelamin</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-if="row.ttd_jk">
                                  {{row.ttd_dob | moment("from", "now", true)}}, {{row.ttd_jk||"-"}}</td>
                                  
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%" v-else>
                                    -
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.ttd_alamat||"-"}}</td>
                                </tr>
                                
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Untuk</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{(row.ttd_untuk == 'Lainnya' ? row.ttd_untuk_lainnya : row.ttd_untuk) ||"-"}}</td>
                                  
                                </tr>
                              </table>

                              <p style="margin: 0;margin-top: 4px;margin-bottom: 4px;">Dengan Pasien Bernama : </p>
                              <table border=0 style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 8px;line-height: 1.5; width: 100%;">
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Nama</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{row.fullname||"-"}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{row.dob|moment("DD MMMM YYYY")}}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;padding-left: 0;" width="12%">Alamat</td>
                                <td style="padding: 4px 8px;" width="20">:</td>
                                <td style="padding: 4px 8px;padding-right: 0;" width="88%">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                              </tr>
                              </table>

                              <p style="margin: 0;margin-bottom: 8px;">Dengan ini menyatakan bahwa saya membuat keputusan dan menyetujui do not resuscitate (jangan diresusitasi).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya menyatakan bahwa jika jantung pasien berhenti berdetak atau jika pasien berhenti bernafas, saya menghendaki tidak dilakukan prosedur medis untuk mengembalikan fungsi jantung dan pernafasan.</p>
                              <p style="margin: 0;margin-bottom: 8px;">Saya memahami bahwa keputusan ini tidak akan mencegah pasien menerima pelayanan kesehatan lainnya seperti pemberian manuver Heimlich atau pemberian oksigen dan langkah-langkah perawatan untuk meningkatkan kenyamanan lainnya.</p>
                              <p style="margin: 0;">Saya memberikan izin agar informasi ini diberikan kepada seluruh staf rumah sakit, saya memahami bahwa saya dapat mencabut pernyataan ini setiap saat.</p>
                            </td>
                          </tr>
                         
                          <tr>
                            <td style="padding: 4px 8px;" width="50%">
                              <div style="text-align:center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_saksi">
                                  <span>Saksi </span> <br />
                                  <img :src="row.arfl_ttd_digital_saksi" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">Saksi </div>
                                </template>
                                <div v-if="row.ttd_name_saksi">({{ row.ttd_name_saksi }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" colspan="2">
                              <div style="text-align:center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                  <span>Magelang, {{now}} </span> <br />
                                  <img :src="row.arfl_ttd_digital" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">Magelang, {{now}} </div>
                                </template>
                                <div style="margin-top:4px">(<span> Keluarga </span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </template>
            <template v-else-if="row.dnr == 'dpjp'">
              <b-col md=4>
                <div class="form-group">
                  <label>Nama Saksi <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_nama_saksi"></b-form-input>
                  <VValidate name="Nama Saksi" v-model="row.ttd_nama_saksi" rules="required" />
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Saksi <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRSaksi">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRSaksi"
                    />
                    <VValidate name="Tanda Tangan Saksi" v-model="row.arfl_ttd_digital_saksi" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Keluarga <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_nama_keluarga"></b-form-input>
                  <VValidate name="Nama Keluarga" v-model="row.ttd_nama_keluarga" rules="required" />
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Keluarga <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNRKeluarga">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNRKeluarga"
                    />
                    <VValidate name="Tanda Tangan Keluarga" v-model="row.arfl_ttd_digital_keluarga" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNRSaksi','arfl_ttd_digital_keluarga')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNRSaksi','arfl_ttd_digital_keluarga')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Nama DPJP <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.nama_dokter"></b-form-input>
                  <VValidate name="Nama DPJP" v-model="row.nama_dokter" rules="required" />
                </div>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan DPJP <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDNR">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdDNR"
                    />
                    <VValidate name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDNR','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdDNR','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md=8>
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                      <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 16px;border-color: #000;line-height: 1.5;" border="1">
                          <tr>
                            <td style="padding: 4px 8px;" width="20%" rowspan="4">
                              <div style="text-align: center;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;width: 56px;" alt="">
                                <p style="margin:0;margin-top: 8px;">
                                  RS Harapan<br />
                                  Jl. P. Senopati No. 11 Magelang
                                </p>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;text-align: center;" colspan="2">
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Formulir Instruksi DPJP</h2>
                              <strong style="margin: 8px 0;">( Dokter Penanggung Jawab Pelayanan )</strong>
                              <h2 style="margin: 0;font-size: 21px;text-transform: uppercase;line-height: 20px;">Kepada Dokter Jaga / Perawat untuk DNR</h2>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="40%"><span>Nama Pasien: <strong>{{row.fullname||"-"}}</strong></span></td>
                            <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{row.nama_dokter||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir: 
                            <strong v-if="row.dob">{{ row.dob | moment("DD MMMM YYYY")}}</strong>
                            <span v-else> - </span>
                            </span></td>
                            <td style="padding: 4px 8px;"><span>Ruang: <strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>No. RM: <strong>{{row.no_rm||"-"}}</strong></span></td>
                            <td></td>
                          </tr>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 16px;line-height: 1.5;">
                          <tr>
                            <td style="padding: 4px 8px;" colspan="3">
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini adalah perintah DPJP kepada dokter jaga/perawat agar tidak boleh melakukan resusitasi bila pasien dengan identitas dibawah ini mengalami henti jantung ( dimana tidak ada denyut nadi ) atau henti nafas ( tidak ada pernafasan spontan ).</p>
                              <p style="margin: 0;margin-bottom: 8px;">Formulir ini juga menginstruksikan kepada dokter jaga/perawat untuk tetap melakukan interval atau pengobatan atau tata laksana lainnya sebelum terjadi henti jantung atau henti nafas.</p>
                              <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 16px;margin-bottom: 12px;line-height: 1.5;">
                                <tr>
                                  <th colspan="3" style="padding: 4px 8px;vertical-align:top;padding-left: 0;text-align: left;">Identitas Pasien</th>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.fullname||"-"}}</td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  <strong v-if="row.dob">{{ row.dob | moment("DD MMMM YYYY")}}</strong>
                                  <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">No. Rekam Medis</td>
                                  <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                  <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.no_rm || "-"}}</td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 4px;"><strong>Pernyataan dan Instruksi Dokter</strong></p>
                              <p style="margin: 0;margin-bottom: 4px;">Saya dokter yang bertanda tangan dibawah ini menginstruksikan tenaga medis emergecy untuk melakukan hal yang tertulis dibawah ini.</p>
                              <table width="100%" border="0" style="font-size: 16px;line-height: 1.5;margin-bottom: 12px;">
                                <tr v-if="row.pernyataan == 'komprehensif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha komprehensif untuk mencegah henti jantung atau henti nafas, TANPA melakukan intubasi, DNR jika henti jantung atau henti nafas terjadi, TIDAK melakukan CPR</p>
                                  </td>
                                </tr>
                                <tr v-else-if="row.pernyataan == 'suportif'">
                                  <td style="vertical-align: top;">
                                    <p style="margin: 0;">Usaha suportif sebelum terjadi henti jantung atau henti nafas yang meliputi pembukaan jalan nafas secara non invasif, pemberian oksigen, mengontrol pendarahahan, memposisikan pasien dengan <span style="text-decoration:underline;" v-if="row.penyataan_desc">{{row.penyataan_desc}},</span> obat-obatan anti alergi, TIDAK melakukan CPR bila henti jantung atau henti nafas terjadi.</p>
                                  </td>
                                </tr>
                              </table>
                              <p style="margin: 0;margin-bottom: 8px;">Saya dokter yang bertanda tangan di bawah ini menyatakan bahwa keputusan DNR di atas diambil setelah pasien/keluarga/wali yang sah diberi penjelasan dan menandatangani informed consent<br/>( Penolakan resusutasi / DNR )</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;" width="33%">
                              <div style="text-align:center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_saksi">
                                  <span>Saksi </span> <br />
                                  <img :src="row.arfl_ttd_digital_saksi" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">Saksi </div>
                                </template>
                                <div v-if="row.ttd_nama_saksi">({{ row.ttd_nama_saksi }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="33%">
                              <div style="text-align:center;">
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_keluarga">
                                  <span>Keluarga </span> <br />
                                  <img :src="row.arfl_ttd_digital_keluarga" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">Keluarga </div>
                                </template>
                                <div v-if="row.ttd_nama_keluarga">({{ row.ttd_nama_keluarga }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td style="padding: 4px 8px;" width="33%">
                              <div style="text-align:center;">
                                <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                  <span>DPJP </span> <br />
                                  <img :src="row.arfl_ttd_digital" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 64px;">DPJP </div>
                                </template>
                                <div v-if="row.nama_dokter">({{ row.nama_dokter }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </template>
          </b-row>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'

  const moment = require('moment')
  const _ = global._

  export default {
    components: {
      Datepicker
    },
    props: {
      id_formulir: Number,
      mrTTD: Array,
    },
    data(){
      return {
        loading: false,
        row: {},
        mrJenisKelamin: [],
        mrUntuk: []
      }
    },
    computed: {
      now(){
        return moment().format('DD MMMM YYYY')
      },
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.row[field] = null
      },
      output(refs,field) {
        try{
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          console.log(isEmpty, data)
          if(!isEmpty){
            this.$set(this.row, field, data)
          }
        }catch(err){
          console.log(err)
        }
      },
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'dnr',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          this.$set(this, 'row', res.data.row)
          this.$set(this, 'mrUntuk', res.data.mrUntuk)

          setTimeout(() => {
            if(this.row.dnr == 'pasien'){
              if(res.data.row.arfl_ttd_digital_perawat){
                this.$refs['ttdDNRPerawat'].fromDataURL(res.data.row.arfl_ttd_digital_perawat)
              }

              if(res.data.row.arfl_ttd_digital_saksi){
                this.$refs['ttdDNRSaksi'].fromDataURL(res.data.row.arfl_ttd_digital_saksi)
              }

              if(res.data.row.arfl_ttd_digital){
                this.$refs['ttdDNRPasien'].fromDataURL(res.data.row.arfl_ttd_digital)
              }
            }else if(this.row.dnr == 'keluarga'){
              if(res.data.row.arfl_ttd_digital){
                this.$refs['ttdDNR'].fromDataURL(res.data.row.arfl_ttd_digital)
              }

              if(res.data.row.arfl_ttd_digital_saksi){
                this.$refs['ttdDNRSaksi'].fromDataURL(res.data.row.arfl_ttd_digital_saksi)
              }
            }else if(this.row.dnr == 'dpjp'){
              if(res.data.row.arfl_ttd_digital_saksi){
                this.$refs['ttdDNRSaksi'].fromDataURL(res.data.row.arfl_ttd_digital_saksi)
              }
              if(res.data.row.arfl_ttd_digital){
                this.$refs['ttdDNR'].fromDataURL(res.data.row.arfl_ttd_digital)
              }
            }
          }, 1500)


          this.$set(this, 'mrJenisKelamin', res.data.mrJenisKelamin)
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      hitSaveDraft(){
        this.row.arfl_template = document.querySelector('.document-framer').innerHTML
        this.row.id_formulir = this.id_formulir

        return Gen.apiRest(
          "/do/" + this.$route.name,
          {
            data: {
              type: 'save-draft',
              payload: this.row
            }
          }
        )
      },
      autoSave: _.debounce(function(){
        this.hitSaveDraft()
      }, 1000),
      saveDraft(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.hitSaveDraft()
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
          }
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              this.row.arfl_template = document.querySelector('.document-framer').innerHTML
              this.row.id_formulir = this.id_formulir

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    payload: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.$emit('successSubmit')
                })
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
      },
    },
    watch:{
      row: {
        handler(){
          this.autoSave()
        },
        deep: true
      }
    }
  }
</script>