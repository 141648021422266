<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
              <b-form-radio-group
                id="ttd"
                v-model="row.arfl_ttd"
                :options="mrTTD"
                name="ttd"
              ></b-form-radio-group>
              <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
            </b-form-group>
          </div>
        </div>
        <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
        <div class="row" v-if="row.arfl_ttd">
          <div class="col-md-4">
            <div class="form-group">
              <label>Nama Penanggung Jawab <span class="text-danger">*</span></label>
              <b-form-input v-model="row.ttd_name_pj"></b-form-input>
              <VValidate name="Nama Penanggung Jawab" v-model="row.ttd_name_pj" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label>Tanggal Lahir Penanggung Jawab <span class="text-danger">*</span></label>
              <datepicker v-model="row.dob_pj" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
              <VValidate name="Tanggal Lahir Penanggung Jawab" v-model="row.dob_pj" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label>Jenis Kelamin Penanggung Jawab <span class="text-danger">*</span></label>
              <b-form-radio-group name="jenis_kelamin" :options="mrJenisKelamin" v-model="row.jk_pj"></b-form-radio-group>
              <VValidate name="Jenis Kelamin Penanggung Jawab" v-model="row.jk_pj" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label>Alamat Penanggung Jawab <span class="text-danger">*</span></label>
              <b-form-textarea v-model="row.alamat_pj"></b-form-textarea>
              <VValidate name="Alamat Penanggung Jawab" v-model="row.alamat_pj" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label>Agama Penanggung Jawab <span class="text-danger">*</span></label>
              <b-form-radio-group :options="mAgama" name="agama_pj" v-model="row.agama_pj"></b-form-radio-group>
              <VValidate name="Agama Penanggung Jawab" v-model="row.agama_pj" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label>Hubungan Dengan Pasien <span class="text-danger">*</span></label>
              <v-select :options="mRelasi" v-model="row.relasi_pj"></v-select>
              <VValidate name="Hubungan Dengan Pasien" v-model="row.relasi_pj" rules="required"></VValidate>
            </div>
            <div class="form-group" v-if="row.arfl_ttd == 'digital'">
              <label>Tanda Tangan Penanggung Jawab <span class="text-danger">*</span></label>
              <div class="signing-element" data-target-id="ttdPelayananKerohanianPj">
                <VueSignaturePad  
                  class="sign-container"
                  width="100%"
                  height="160px"
                  ref="ttdPelayananKerohanianPj"
                />
                <VValidate name="Tanda Tangan Penanggung Jawab" v-model="row.arfl_ttd_digital_pj" rules="required"></VValidate>
                <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdPelayananKerohanianPj','arfl_ttd_digital_pj')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button @click.prevent="output('ttdPelayananKerohanianPj','arfl_ttd_digital_pj')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Nama Petugas <span class="text-danger">*</span></label>
              <b-form-input v-model="row.ttd_name"></b-form-input>
              <VValidate name="Nama Petugas" v-model="row.ttd_name" rules="required"></VValidate>
            </div>
            <div class="form-group" v-if="row.arfl_ttd == 'digital'">
              <label>Tanda Tangan <span class="text-danger">*</span></label>
              <div class="signing-element" data-target-id="ttdPelayananKerohanian">
                <VueSignaturePad  
                  class="sign-container"
                  width="100%"
                  height="160px"
                  ref="ttdPelayananKerohanian"
                />
                <VValidate  name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdPelayananKerohanian','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button @click.prevent="output('ttdPelayananKerohanian','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="document-framer">
              <div class="df-content">
                <div style="position: relative;max-width: 700px;">
                  <!-- Copas Template dari sini -->
                  <div
                    style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                    <div>
                      <table
                        style="border-collapse: collapse;width: 100%;font-size: 12px;border-color: #000;line-height: 1.5;"
                        border="1">
                        <tr>
                          <td style="padding: 4px 8px;" width="20%" rowspan="4">
                            <div style="text-align: center;">
                              <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                              <h2
                                style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">
                                Rumah Sakit Harapan</h2>
                            </div>
                          </td>
                          <td style="padding: 4px 8px;" width="40%"><span>No. RM:
                              <strong>{{row.no_rm}}</strong></span></td>
                          <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{ row.nama_dokter }}</strong></span></td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{row.fullname}}</strong></span></td>
                          <td style="padding: 4px 8px;"><span>NIK: <strong>{{row.nik}}</strong></span>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;"><span>Tgl. Lahir: <strong>{{ row.dob | moment('DD MMMM YYYY') }}</strong></span></td>
                          <td style="padding: 4px 8px;"><span>Jenis Kelamin: <strong>{{ row.jenis_kelamin_text }}</strong></span></td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;"><span>Tgl. Masuk: <strong>{{ row.reg_date | moment('DD MMMM YYYY, HH:mm') }} WIB</strong></span>
                          </td>
                          <td style="padding: 4px 8px;"><span>Ruang: <strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</span></td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" colspan="3">
                            <div style="text-transform: uppercase;text-align: center;">
                              <h3
                                style="margin: 0;font-size: 14px;text-transform: uppercase;line-height: 18px;margin-top: 4px;">
                                Formulir Permintaan Pelayanan Kerohanian </h3>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table style="border-collapse: collapse;width: 100%; border: 1px solid; font-size: 12px;line-height: 1.5;">
                        <tr>
                          <td style="padding: 4px 8px;" colspan="2">
                            <p style="margin: 0;margin-bottom: 4px;">Yang bertanda tangan dibawah ini:</p>
                            <table
                              style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 8px;line-height: 1.5;">
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.ttd_name_pj }}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.dob_pj | moment('DD MMMM YYYY') }}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">
                                  Umur/Jenis Kelamin</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">
                                  {{ calculateAge(row.dob_pj) }} tahun, {{ row.jk_pj }}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat
                                </td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.alamat_pj }}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Agama
                                </td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.agama_pj}}</td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Hubungan dengan Pasien</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.relasi_pj }}</td>
                              </tr>

                              <tr>
                                <td colspan="3" style="padding: 4px 8px;vertical-align:top;padding-left: 0;">
                                  Dengan ini
                                  menyatakan permintaan pelayanan
                                  kerohanian agama kepada Rumah Sakit Harapan terhadap pasien:</td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama
                                  Pasien</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.fullname }}</td>
                              </tr>
                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal
                                  Lahir</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.dob | moment('DD MMMM YYYY') }}</td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat
                                </td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Dalam Bentuk</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="vertical-align:top;" width="88%">
                                  <table style="border-collapse: collapse;font-size: 12px;">
                                    <tr>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.layanan == 'doa'">Doa</span>
                                      </td>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.layanan == 'konsultasi'">Konsultasi</span>
                                      </td>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.layanan == 'sakramen_perminyakan'">Sakramen Perminyakan </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.layanan == 'pendampingan'"> Pendampingan</span>
                                      </td>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.layanan == 'lain'"> {{ row.layanan_lain }}</span>
                                      </td>
                                      <td style="padding: 4px 8px;"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Diberikan oleh</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="vertical-align:top;" width="88%">
                                  <table style="border-collapse: collapse;font-size: 12px;">
                                    <tr>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.pemberi_layanan == 'pembimbing_rsh'"> Pembimbing rohani dari Rumah Sakit Harapan</span>
                                      </td>
                                      <td style="padding: 4px 8px;">
                                        <span v-if="row.pemberi_layanan == 'pembimbing_pribadi'"> Pembimbing rohani pribadi </span>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama Pembimbing Rohani</td>
                                <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                <td style="vertical-align:top;" width="88%">
                                  <table style="border-collapse: collapse;font-size: 12px;">
                                    <tr>
                                      <td style="padding: 4px 8px;">
                                        <span>{{row.nama_pembimbing}}</span>
                                      </td>
                                      <td style="padding: 4px 8px;"></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <p style="margin: 0;margin-bottom: 8px;">Demikian surat permohonan permintaan pelayanan kerohanian ini saya buat, atas perhatiannya saya ucapkan terima kasih.</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                <span>Petugas</span> <br>
                                <img :src="row.arfl_ttd_digital" alt="signature" width="200" id="ttdPasien">
                                <br />
                              </template>
                              <template v-else>
                                <div style="margin-bottom: 48px;">Petugas</div>
                              </template>
                              <div v-if="row.ttd_name">({{ row.ttd_name }})</div>
                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </div>
                          </td>
                          <td style="padding: 4px 8px;" width="50%">
                            <div style="text-align:center;">
                              <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_pj">
                                <div style="margin-bottom: 0;">Magelang, {{now}}</div>
                                <span>Pasien & Keluarga</span><br>
                                <img :src="row.arfl_ttd_digital_pj" alt="signature" width="200" id="ttdPasien">
                                <br />
                              </template>
                              <template v-else>
                                <div style="margin-bottom: 0;">Magelang, {{now}}</div>
                                <div style="margin-bottom: 48px;">Pasien & Keluarga</div>
                              </template>
                              <div v-if="row.ttd_name_pj">({{ row.ttd_name_pj }})</div>
                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <!-- End Copas Template -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-row class="mt-4 text-center">
          <b-col class="col-md-12">
            <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
            <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
            <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import Gen from '@/libs/Gen.js'
const moment = require('moment')
const _ = global._

export default {
  components: {
    Datepicker
  },
  props: {
    mrTTD: Array,
    id_formulir: Number
  },
  data(){
    return {
      loading: false,
      row: {},
      mrJenisKelamin: [
        {text: 'Laki-laki', value: 'L'},
        {text: 'Perempuan', value: 'P'},
      ],
      mAgama: [],
      mRelasi: []
    }
  },
  computed: {
    now(){
      return moment().format('DD MMMM YYYY')
    }
  },
  mounted(){
    this.GetPasienData()
  },
  methods: {
    calculateAge(birthDate) {
        if(!birthDate) return '-'
        const today = new Date()
        const birthDateObj = new Date(birthDate)
      
        let age = today.getFullYear() - birthDateObj.getFullYear()
        const monthDiff = today.getMonth() - birthDateObj.getMonth()
        
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
            age--
        }
        
        return age
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        if(!isEmpty){
          this.$set(this.row, field, data)
        }
      }catch(err){
        console.log(err)
      }
    },
    GetPasienData(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$route.name + '/get-data',
        {
          params: {
            type: 'pelayanan-kerohanian',
            id_formulir: this.id_formulir,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        this.$set(this, 'row', res.data.row)
        this.$set(this, 'mAgama', res.data.mAgama)
        this.$set(this, 'mRelasi', res.data.mRelasi)

        setTimeout(() => {
          if(res.data.row.arfl_ttd_digital){
            this.$refs['ttdPelayananKerohanian'].fromDataURL(res.data.row.arfl_ttd_digital)
          }
          if(res.data.row.arfl_ttd_digital_pj){
            this.$refs['ttdPelayananKerohanianPj'].fromDataURL(res.data.row.arfl_ttd_digital_pj)
          }
        }, 1500)

        setTimeout(() => {
          document.querySelectorAll('.romawi').forEach((v, i) => {
            v.innerHTML = this.romawi[i + 1] + '.'
          })
        }, 200)
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    hitSaveDraft(){
      this.row.arfl_template = document.querySelector('.document-framer').innerHTML
      this.row.id_formulir = this.id_formulir

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft',
            payload: this.row
          }
        }
      )
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          this.hitSaveDraft()
          .then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$emit('successSubmit')
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    doSubmit(){
      this.$refs.VFormTTD.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.row.arfl_template = document.querySelector('.document-framer').innerHTML
            this.row.id_formulir = this.id_formulir

            Gen.apiRest(
              "/do/" + this.$route.name,
              {
                data: {
                  type: 'submit-ttd',
                  payload: this.row
                }
              }
            )
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>