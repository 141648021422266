<template>
  <div>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div class="content">
      <div v-if="!this.$route.params.type">
        <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Formulir Lainnya</h5>
                  <div>
                    <b-button @click="getList()" v-b-tooltip.hover.noninteractive="'Muat ulang'" variant="primary">
                       <i class="icon-spinner11"></i>
                    </b-button>
                    <a @click.prevent="openModalAdd" href="javascript:;" class="mx-2 btn bg-info-600 btn-labeled btn-labeled-left" v-if="hasAdd">
                      <b><i class="icon-plus2"></i></b>Tambah Dokumen
                    </a>
                  </div>
                </div>
              </div>
              <b-table-simple class="table-sm patient-table" bordered>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama Dokumen</th>
                    <th>Tanggal Pembuatan</th>
                    <th>PPA/Nama</th>
                    <th>Status</th>
                    <th>Keterangan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loading.coreData">
                    <tr>
                      <td colspan="7" class="text-center">Loading...</td>
                    </tr>
                  </template>
                  <template v-else-if="(data.data || []).length < 1">
                    <tr>
                      <td colspan="7" class="text-center">Tidak Terdapat Formulir Lainnya.</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(value, index) in data.data" :key="index">
                      <td>{{(data.per_page*(data.current_page-1))+index+1}}</td>
                      <td>{{ value.arfl_name }}</td>
                      <td>
                        <span v-if="value.arfl_created_date">{{ value.arfl_created_date | moment("DD MMMM YYYY, HH:mm") }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.arfl_created_by_name">{{ value.arfl_created_by_level }} / {{ value.arfl_created_by_name }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <b-badge v-if="value.arfl_status == 'BELUM'" variant="warning">Tersedia</b-badge>
                        <b-badge v-else-if="value.arfl_status == 'TTD_DOC'" variant="primary">TTD Dokumen</b-badge>
                        <b-badge v-else-if="value.arfl_status == 'UNGGAH_TTD'" variant="info">Unggah TTD</b-badge>
                        <b-badge v-else-if="value.arfl_status == 'SELESAI'" variant="success">Selesai</b-badge>
                        <b-badge v-else-if="value.arfl_status == 'BELUM_TERSEDIA'" variant="danger">Belum Tersedia</b-badge>
                      </td>
                      <td>
                        <span v-if="!value.arfl_keterangan">-</span>
                        <small v-else>{{ value.arfl_keterangan }}</small>
                      </td>
                      <td>
                        <template v-if="(value.arfl_status == 'BELUM' || value.arfl_status == 'TTD_DOC') && hasAdd">
                          <a href="javascript:;" v-b-tooltip.hover.right.noninteractive=" value.arfl_status == 'BELUM' ? 'Isi Dokumen' : 'Ubah Isi Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" @click.prevent="$router.push({name: $route.name, params: {pageSlug: pageSlug, type: value.arfl_slug, id: value.arfl_id}})">
                            <i class="icon-plus-circle2"></i>
                          </a>
                        </template>
                        <template v-if="value.arfl_status == 'TTD_DOC' && hasAdd">
                          <a @click.prevent="openModal(value)" v-b-tooltip.hover.right.noninteractive="'Tanda Tangani Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800 btn-icon">
                            <i class="icon-pencil"></i>
                          </a>
                        </template>
                        <template v-else-if="value.arfl_status == 'UNGGAH_TTD' && hasAdd">
                          <a href="javascript:;" v-b-tooltip.hover.right.noninteractive="'Ubah Isi Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" @click.prevent="$router.push({name: $route.name, params: {pageSlug: pageSlug, type: value.arfl_slug, id: value.arfl_id}})">
                            <i class="icon-plus-circle2"></i>
                          </a>
                          <a v-if="value.arfl_slug != 'surat-kematian' && value.arfl_slug != 'serah-terima-bayi' && value.arfl_slug != 'surat-keterangan-lahir'" @click.prevent="openModal(value)" v-b-tooltip.hover.right.noninteractive="'Ubah Tanda Tangan'" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800 btn-icon">
                            <i class="icon-pencil"></i>
                          </a>
                          <a @click.prevent="uploadTTDDoc(value)" target="_blank" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right.noninteractive="'Unggah DokumenTTD'">
                            <i class="icon-upload"></i>
                          </a>
                          <a v-if="value.arfl_file" :href="uploader(value.arfl_file)" target="_blank" class="btn btn-sm alpha-danger border-danger btn-icon rounded-round text-danger-700" v-b-tooltip.hover.right.noninteractive="'Unduh Template Dokumen'">
                            <i class="icon-download"></i>
                          </a>
                        </template>
                        <template v-else-if="value.arfl_status == 'SELESAI' && hasAdd">
                          <template v-if="value.arfl_slug == 'surat-rujukan'">
                            <a href="javascript:;" v-b-tooltip.hover.right.noninteractive=" value.arfl_status == 'BELUM' ? 'Isi Dokumen' : 'Ubah Isi Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" @click.prevent="$router.push({name: $route.name, params: {pageSlug: pageSlug, type: value.arfl_slug, id: value.arfl_id}})">
                              <i class="icon-pencil"></i>
                            </a>
                            
                            <a v-if="value.arfl_file" :href="uploader(value.arfl_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                          </template>

                          <template v-else>                        
                            <template v-if="value.arfl_is_upload_manual == 'Y'">
                              <a v-if="value.arfl_file" :href="uploader(value.arfl_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                            </template>
                            <template v-else-if="value.arfl_is_upload_manual == 'N'">
                              <a href="javascript:;" v-b-tooltip.hover.right.noninteractive="value.arfl_slug == 'surat-kematian' ? 'Ubah Dokumen' : 'Tambah Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" @click.prevent="$router.push({name: $route.name, params: {pageSlug: pageSlug, type: value.arfl_slug, id: value.arfl_id}})">
                                <i class="icon-plus-circle2"></i>
                              </a>
                            </template>
                          </template>
                        </template>

                        <template v-if="(value.list_document || []).length">
                          <a @click.prevent="openListDoc(value.list_document)" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right.noninteractive="'Daftar Dokumen'">
                            <i class="icon-list"></i>
                          </a>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </b-table-simple>

              <div class="card-footer">
                <b-pagination
                  class="mb-0"
                  v-model="pageNo"
                  :per-page="data.per_page"
                  :total-rows="data.total"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormTransferRumahSakit v-else-if="this.$route.params.type == 'transfer-antar-rumah-sakit'" />
      <FormPenyebabKematian v-else-if="this.$route.params.type == 'surat-keterangan-penyebab-kematian'" />
      <FormPelayananKerohanian v-else-if="this.$route.params.type == 'pelayanan-kerohanian'" />
      <FormPenolakanPengobatan v-else-if="this.$route.params.type == 'penolakan-pengobatan'" />
      <FormPermintaanSecondOpinion v-else-if="this.$route.params.type == 'permintaan-second-opinion'" />
      <FormSuratKeteranganOpname v-else-if="this.$route.params.type == 'surat-keterangan-opname'" />
      <FormSuratKeteranganIstirahat v-else-if="this.$route.params.type == 'surat-keterangan-istirahat'" />
      <FormSuratPulangPermintaanSendiri v-else-if="this.$route.params.type == 'surat-pulang-atas-permintaan-sendiri'" />
      <FormSuratRujukan v-else-if="this.$route.params.type == 'surat-rujukan'" />
      <FormIzinPulangSementara v-else-if="this.$route.params.type == 'izin-pulang-sementara'" />
      <FormDNR v-else-if="this.$route.params.type == 'dnr'" />
      <FormPenggunaanSusuFormula v-else-if="this.$route.params.type == 'persetujuan-penggunaan-susu-formula'" />
      <FormSuratKeteranganLahir v-else-if="this.$route.params.type == 'surat-keterangan-lahir'" :mrTTD="mrTTD" @setLoadingOverlay="setLoadingOverlay" />
      <FormSerahTerimaBayi v-else-if="this.$route.params.type == 'serah-terima-bayi'" :mrTTD="mrTTD" @setLoadingOverlay="setLoadingOverlay" />
      <FormSuratKematian v-else-if="this.$route.params.type == 'surat-kematian'" :mrTTD="mrTTD" @setLoadingOverlay="setLoadingOverlay" />

    </div>

    <b-modal id="TTDModal" v-model="showModal" title="Tanda Tangani Dokumen" size="document-signing" hide-footer no-close-on-backdrop>
      <TTDTransferRumahSakit :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-if="ttdForm == 'transfer-antar-rumah-sakit'" />
      <TTDSuratKematian :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-kematian'" />
      <TTDPenyebabKematian :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-keterangan-penyebab-kematian'" />
      <TTDPelayananKerohanian :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'pelayanan-kerohanian'" />
      <TTDPenolakanPengobatan :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'penolakan-pengobatan'" />
      <TTDPermintaanSecondOpinion :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'permintaan-second-opinion'" />
      <TTDSuratKeteranganOpname :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-keterangan-opname'" />
      <TTDSuratKeteranganIstirahat :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-keterangan-istirahat'" />
      <TTDSuratPulangPermintaanSendiri :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-pulang-atas-permintaan-sendiri'" />
      <TTDIzinPulangSementara :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'izin-pulang-sementara'" />
      <TTDSuratRujukan :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'surat-rujukan'" />
      <TTDDNR :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'dnr'" />
      <TTDPenggunaanSusuFormula :mrTTD="mrTTD" :id_formulir="id_formulir" @successSubmit="successSubmit" @closeModal="closeModal" @setLoadingOverlay="setLoadingOverlay" v-else-if="ttdForm == 'persetujuan-penggunaan-susu-formula'" />
    </b-modal>

    <b-modal id="UploadDocTTD" title="Unggah Dokumen TTD" size="md" hide-footer no-close-on-backdrop>
      <div>
        <validation-observer ref="VFormUploadDoc" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(doUploadDoc)">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <Uploader v-model="dataModal.file" isDocument type="doc_only"></Uploader>
                  <VValidate name="Dokumen" v-model="dataModal.file" rules="required" />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 text-right">
                <b-button type="button" variant="secondary" @click.prevent="closeModalTTD">Batalkan</b-button>
                <b-button type="submit" variant="primary" class="ml-2">Submit <i class="icon-paperplane ml-2"></i></b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal id="ListDocument" title="Daftar Dokumen" size="lg" hide-footer no-close-on-backdrop>
      <div>
        <b-table-simple bordered responsive>
          <b-tr>
            <b-th>No</b-th>
            <b-th>Nama</b-th>
            <b-th>Tanggal Pembuatan</b-th>
            <b-th>PPA/Nama</b-th>
            <b-th>File</b-th>
          </b-tr>
          <b-tr v-for="(value, index) in dataModal.list_document" :key="index">
            <b-td>{{ index + 1 }}</b-td>
            <b-td>{{ value.arflr_name }}</b-td>
            <b-td>{{ value.arflr_created_date | moment('DD MMMM YYYY, HH:mm') }}</b-td>
            <b-td>{{ value.arflr_created_by_level }}/{{ value.arflr_created_by_name }}</b-td>
            <b-td>
              <a v-if="value.arflr_file" :href="uploader(value.arflr_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
            </b-td>
          </b-tr>
        </b-table-simple>
      </div>
    </b-modal>

    <b-modal id="modal-add" title="Tambah Dokumen" no-close-on-backdrop hide-footer :hide-header-close="true">
      <div>
        <validation-observer ref="VFormAdd" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(doAddDocument)">
            <b-row>
              <b-col md=12>
                <b-form-group>
                  <label>Nama Dokumen <span class="text-danger">*</span></label>
                  <b-form-input v-model="dataModal.arfl_name"></b-form-input>
                  <VValidate name="Nama Dokumen" rules="required|min:3" v-model="dataModal.arfl_name" />
                </b-form-group>
              </b-col>
              <b-col md=12>
                <b-form-group>
                  <Uploader v-model="dataModal.arfl_file" isDocument type="doc_only"></Uploader>
                  <VValidate name="Dokumen" v-model="dataModal.arfl_file" rules="required" />
                </b-form-group>
              </b-col>
              <b-col md=12>
                <b-form-group>
                  <label>Keterangan</label>
                  <b-form-textarea v-model="dataModal.arfl_keterangan"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-right">
                <b-button type="button" @click="closeModalAdd" class="mr-2">Batal</b-button>
                <b-button type="submit" class="btn-labeled btn-labeled-left" variant="success"><b><i class="icon-checkmark"></i></b> Simpan</b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'

import FormTransferRumahSakit from './FormTransferRumahSakit.vue'
import TTDTransferRumahSakit from './TTDTransferRumahSakit.vue'

import FormSuratKematian from './FormSuratKematian.vue'
import TTDSuratKematian from './TTDSuratKematian.vue'

import FormPenyebabKematian from './FormPenyebabKematian.vue'
import TTDPenyebabKematian from './TTDPenyebabKematian.vue'

import FormPelayananKerohanian from './FormPelayananKerohanian.vue'
import TTDPelayananKerohanian from './TTDPelayananKerohanian.vue'

import FormPenolakanPengobatan from './FormPenolakanPengobatan.vue'
import TTDPenolakanPengobatan from './TTDPenolakanPengobatan.vue'

import FormPermintaanSecondOpinion from './FormPermintaanSecondOpinion.vue'
import TTDPermintaanSecondOpinion from './TTDPermintaanSecondOpinion.vue'

import FormSuratKeteranganOpname from './FormSuratKeteranganOpname.vue'
import TTDSuratKeteranganOpname from './TTDSuratKeteranganOpname.vue'

import FormSuratKeteranganIstirahat from './FormSuratKeteranganIstirahat.vue'
import TTDSuratKeteranganIstirahat from './TTDSuratKeteranganIstirahat.vue'

import FormSuratPulangPermintaanSendiri from './FormSuratPulangPermintaanSendiri.vue'
import TTDSuratPulangPermintaanSendiri from './TTDSuratPulangPermintaanSendiri.vue'

import FormIzinPulangSementara from './FormIzinPulangSementara.vue'
import TTDIzinPulangSementara from './TTDIzinPulangSementara.vue'

import FormSuratRujukan from './FormSuratRujukan.vue'
import TTDSuratRujukan from './TTDSuratRujukan.vue'

import FormDNR from './FormDNR.vue'
import TTDDNR from './TTDDNR.vue'

import FormSuratKeteranganLahir from './FormSuratKeteranganLahir.vue'
import FormSerahTerimaBayi from './FormSerahTerimaBayi.vue'

import FormPenggunaanSusuFormula from './FormPenggunaanSusuFormula.vue'
import TTDPenggunaanSusuFormula from './TTDPenggunaanSusuFormula.vue'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    FormTransferRumahSakit,
    TTDTransferRumahSakit,

    FormSuratKematian,
    TTDSuratKematian,

    FormPenyebabKematian,
    TTDPenyebabKematian,
    
    FormPelayananKerohanian,
    TTDPelayananKerohanian,

    FormPenolakanPengobatan,
    TTDPenolakanPengobatan,

    FormPermintaanSecondOpinion,
    TTDPermintaanSecondOpinion,

    FormSuratKeteranganOpname,
    TTDSuratKeteranganOpname,

    FormSuratKeteranganIstirahat,
    TTDSuratKeteranganIstirahat,

    FormSuratPulangPermintaanSendiri,
    TTDSuratPulangPermintaanSendiri,

    FormIzinPulangSementara,
    TTDIzinPulangSementara,

    FormSuratRujukan,
    TTDSuratRujukan,

    FormDNR,
    TTDDNR,

    FormPenggunaanSusuFormula,
    TTDPenggunaanSusuFormula,

    FormSuratKeteranganLahir,
    FormSerahTerimaBayi
  },
  data(){
    return {
      loading: {
        patientInfo: false,
        coreData: false
      },
      patientData: {},
      data: {},
      dataModal: {},
      ttdForm: '',
      id_formulir: null,
      mrTTD: [
        {text: 'TTD Basah / Manual', value: 'manual'},
        {text: 'TTD Digital', value: 'digital'}
      ],
      showModal: false
    }
  },
  mounted(){
    this.getPatientInfo()

    if(!this.$route.params.type){
      this.getList()
    }

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId == 'UploadDocTTD'){
        this.closeModalTTD()
      }
    })
  },

  computed: {
    hasAdd(){
      return this.moduleRole('add')
    }
  },

  methods: {
    doAddDocument(){
      this.$refs.VFormAdd.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.loadingOverlay = true

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'new-document',
                    registrasi_id: this.$route.params.pageSlug,
                    ap_id: this.patientData.ap_id,
                    ...this.dataModal
                  }
                }
              )
              .then(res => {
                this.loadingOverlay = false
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.closeModalAdd()
                  this.getList()
                })
              })
              .catch(err => {
                this.loadingOverlay = false
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
    },
    openModalAdd(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.show('modal-add')
    },
    closeModalAdd(){
      this.$bvModal.hide('modal-add')
      this.dataModal = {}
    },
    openListDoc(val){
      this.dataModal = {
        list_document: val
      }
      this.$bvModal.show('ListDocument')
    },
    closeModalTTD(){
      this.$bvModal.hide('UploadDocTTD')
      this.dataModal = {}
    },
    uploadTTDDoc(value){
      this.dataModal.id = value.arfl_id
      this.$bvModal.show('UploadDocTTD')
    },
    doUploadDoc(){
      this.$refs.VFormUploadDoc.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.loadingOverlay = true

            Gen.apiRest(
              "/do/" + this.modulePage, {
                data: {
                  type: 'upload-doc-ttd',
                  ...this.dataModal
                }
              }
            ).then(res => {
              this.loadingOverlay = false
              
              this.$swal({
                icon: 'success',
                text: res.data.message
              }).then(() => {
                this.closeModalTTD()
                this.getList()
              })
            }).catch(err => {
              this.loadingOverlay = false
              console.log(err)
              this.$swal({
                icon: 'error',
                text: err.response.data.message || 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
    successSubmit(){
      this.closeModal()

      this.getList()
    }, 
    closeModal(){
      this.ttdForm = ''
      this.id_formulir = null
      this.showModal = false
    },
    openModal(value){
      this.ttdForm = value.arfl_slug
      this.id_formulir = value.arfl_id
      this.showModal = true
      setTimeout(()=>{
        console.log('resize')
        $(".sign-container canvas").attr("width",400)
        $(".sign-container canvas").attr("height",160)
      },200)
    },
    setLoadingOverlay(val){
      this.loadingOverlay = val
    },
    getPatientInfo(){
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },
    getList(){
      this.$set(this.loading, 'coreData', true)

      Gen.apiRest(
        "/get/" + this.$route.name,
        {
          params: {
            id_registrasi: this.pageSlug,
            page: this.filter.page || 1,
            shown: this.filter.shown
          }
        }
      ).then(res => {
        this.$set(this, 'data', res.data.data)
        this.$set(this.loading, 'coreData', false)
      }).catch(err => {
        this.$set(this.loading, 'coreData', false)
      })
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
      if(!this.$route.params.type){
        this.getList()
      }
    }
  },
}
</script>