<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
          <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <div class="row" v-if="row.arfl_ttd">
            <div class="col-md-4">
              <div class="form-group">
                <label>Nama Penanda Tangan <span class="text-danger">*</span></label>
                <b-form-input v-model="row.ttd_name"></b-form-input>
                <VValidate name="Nama Penanda Tangan" v-model="row.ttd_name" rules="required" />
              </div>
              <div class="form-group">
                <label>Tanggal Lahir Penanda Tangan <span class="text-danger">*</span></label>
                <datepicker v-model="row.ttd_dob" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                <VValidate name="Tanggal Lahir Penanda Tangan" v-model="row.ttd_dob" rules="required" />
              </div>
              <div class="form-group">
                <label>Alamat <span class="text-danger">*</span></label>
                <b-form-textarea v-model="row.ttd_alamat"></b-form-textarea>
                <VValidate name="Alamat" v-model="row.ttd_alamat" rules="required" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <label>Tanda Tangan <span class="text-danger">*</span></label>
                <div class="signing-element" data-target-id="ttdSecondOpinion">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinion"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinion','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinion','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Tanggal / Waktu Pengembalian Dokumen <span class="text-danger">*</span></label>
                <date-picker :show-second="false" v-model="row.waktu_pengembalian" type="datetime" value-type="YYYY-MM-DD HH:mm" placeholder="-- Tanggal --"></date-picker>
                <VValidate name="Tanggal / Waktu Pengembalian Dokumen" v-model="row.waktu_pengembalian" rules="required" />
              </div>

              <div class="form-group">
                <label>Nama Dokter <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_dokter"></b-form-input>
                <VValidate name="Nama Dokter" rules="required" v-model="row.nama_dokter" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSecondOpinionDokter">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinionDokter"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTanganDokter" v-model="row.arfl_ttd_digital_dokter" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinionDokter','arfl_ttd_digital_dokter')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinionDokter','arfl_ttd_digital_dokter')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label>Nama Petugas (atas) <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_petugas"></b-form-input>
                <VValidate name="Nama Petugas" vid="nama_petugas_atas" rules="required" v-model="row.nama_petugas" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSecondOpinionPetugas">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinionPetugas"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTanganPetugas" v-model="row.arfl_ttd_digital_petugas" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinionPetugas','arfl_ttd_digital_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinionPetugas','arfl_ttd_digital_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <label>Nama Saksi <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_saksi"></b-form-input>
                <VValidate name="Nama Saksi" rules="required" v-model="row.nama_saksi" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSecondOpinionSaksi">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinionSaksi"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTanganSaksi" v-model="row.arfl_ttd_digital_saksi" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinionSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinionSaksi','arfl_ttd_digital_saksi')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <label>Nama Petugas (bawah) <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_petugas_2"></b-form-input>
                <VValidate name="Nama Petugas" vid="nama_petugas_2" rules="required" v-model="row.nama_petugas_2" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSecondOpinionPetugasBawah">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinionPetugasBawah"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTanganPetugasBawah" v-model="row.arfl_ttd_digital_petugas_2" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinionPetugasBawah','arfl_ttd_digital_petugas_2')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinionPetugasBawah','arfl_ttd_digital_petugas_2')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <label>Nama Peminjam <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_peminjam"></b-form-input>
                <VValidate name="Nama Peminjam" vid="nama_peminjam" rules="required" v-model="row.nama_peminjam" />
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSecondOpinionPeminjam">
                  <VueSignaturePad  
                    class="sign-container"
                    width="100%"
                    height="160px"
                    ref="ttdSecondOpinionPeminjam"
                  />
                  <VValidate name="Tanda Tangan" vid="ttdPenandaTanganPeminjam" v-model="row.arfl_ttd_digital_peminjam" rules="required"></VValidate>
                  <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdSecondOpinionPeminjam','arfl_ttd_digital_peminjam')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button @click.prevent="output('ttdSecondOpinionPeminjam','arfl_ttd_digital_peminjam')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="document-framer">
                <div class="df-content">
                  <div style="position: relative;max-width: 700px;">
                    <!-- Copas Template dari sini -->
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                      <div>
                        <table border="1" style="border-collapse: collapse;width: 100%;font-size: 12px;border-color: #000;line-height: 1.5;">
                          <tbody>
                            <tr>
                              <td style="padding: 4px 8px;" width="20%" rowspan="4">
                                <div style="text-align: center;">
                                  <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                  <p style="margin:0;margin-top: 8px;"> RS Harapan<br> Jl. P. Senopati No. 11 Magelang</p>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;text-align: center;" colspan="2">
                                <h2 style="margin: 0 0 10px 0;font-size: 30px;text-transform: uppercase;line-height: 20px;">Second Opinion</h2>
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;">(Permohonan Meminta Pendapat Lain)</h2>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;" width="40%"><span>Nama Pasien: <strong>{{row.fullname}}</strong></span></td>
                              <td style="padding: 4px 8px;" width="40%"><span>DPJP: <strong>{{row.nama_dokter}}</strong></span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>Tgl. Lahir: <strong>{{row.dob | moment('DD MMMM YYYY')}}</strong></span></td>
                              <td style="padding: 4px 8px;"><span>Ruang: <strong>{{row.kamar}}</strong> {{ row.bangsal }} - {{row.kelas}}</span></td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;"><span>No. RM: <strong>{{row.no_rm}}</strong></span></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;font-size: 12px;line-height: 1.5; border: 1px solid #000;">
                          <tbody>
                            <tr>
                              <td style="padding: 4px 8px;" colspan="2">
                                <table border="0" style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                  <tbody>
                                    <tr>
                                      <th colspan="3" style="padding: 4px 8px;vertical-align:top;padding-left: 0;text-align: left;">
                                        Yang
                                        bertanda tangan dibawah ini :</th>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.ttd_name }}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.ttd_dob | moment('DD MMMM YYYY')}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.ttd_alamat }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p style="margin: 0;margin-bottom: 4px;">Dengan ini menyatakan permohonan meminta pendapat lain (second opinion) untuk pasien :</p>
                                <table border="0" style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                  <tbody>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Nama</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{row.fullname}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Tanggal Lahir</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.dob | moment('DD MMMM YYYY') }}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">Alamat</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-left: 0;" width="17.5%">No. RM</td>
                                      <td style="padding: 4px 8px;vertical-align:top;" width="20">:</td>
                                      <td style="padding: 4px 8px;vertical-align:top;padding-right: 0;" width="88%">{{ row.no_rm }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p style="margin: 0;margin-bottom: 4px;">Bahwa dengan ini menyatakan dengan sadar dan sesungguhnya bahwa:</p>
                                <table border="0" width="100%" style="font-size: 12px;line-height: 1.5;margin-bottom: 12px;">
                                  <tbody>
                                    <tr>
                                      <td style="vertical-align: top;" colspan="3">
                                        <ol style="margin:0; padding-left: 20px;">
                                          <li>Telah menerima dan memahami informasi mengenai kondisi terhadap diri saya / pasien dan tindakan penanganan awal yang telah dilakukan dari pihak Rumah Sakit.</li>
                                          <li>Meminta kepada pihak Rumah Sakit untuk diberikan kesempatan mencari second opinion terhadap alternatif diagnosis / pengobatan diri saya / pasien ke
                                            dokter <span>{{row.nama_dokter_tujuan}}</span>di
                                            Instansi 
                                            <span>{{row.nama_rs_tujuan}}</span>
                                          </li>
                                          <li>Segala sarana, biaya maupun fasilitas untuk mencari second opinion
                                            adalah
                                            tanggung jawab diri saya / pasien/ keluarga.</li>
                                          <li>Untuk keperluan tersebut diatas, meminjam hasil pemeriksaan penunjang
                                            kesehatan
                                            saya/pasien berupa :
                                            <ol type="a" style="margin:0; padding-left: 20px;">
                                              <li v-for="(value, index) in row.penjelasan" :key="index">{{ value.deskripsi }}</li>
                                            </ol>
                                          </li>
                                        </ol>
                                        <p style="margin: 0;"></p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p style="margin: 0;margin-bottom: 8px;">Demikian pernyataan ini saya buat dengan
                                  sebenar-benarnya. </p>
                                <p style="margin-top: 0;margin-bottom: 8px; text-align: right;">Magelang, {{ now }}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 12px;line-height: 1.5;">
                          <tbody>
                            <tr>
                              <td style="padding: 4px 8px;" width="25">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_dokter">
                                    <span>Dokter </span> <br />
                                    <img :src="row.arfl_ttd_digital_dokter" alt="signature" width="160">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Dokter </div>
                                  </template>
                                  <div v-if="row.nama_dokter">({{ row.nama_dokter }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="25">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_petugas">
                                    <span>Petugas </span> <br />
                                    <img :src="row.arfl_ttd_digital_petugas" alt="signature" width="160">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Petugas </div>
                                  </template>
                                  <div v-if="row.nama_petugas">({{ row.nama_petugas }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="25">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_saksi">
                                    <span>Saksi </span> <br />
                                    <img :src="row.arfl_ttd_digital_saksi" alt="signature" width="160">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Saksi </div>
                                  </template>
                                  <div v-if="row.nama_saksi">({{ row.nama_saksi }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;" width="25">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                    <span>Saya yang menyatakan </span> <br />
                                    <img :src="row.arfl_ttd_digital" alt="signature" width="150">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Saya yang menyatakan </div>
                                  </template>
                                  <div v-if="row.ttd_name">({{ row.ttd_name }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 4px 8px;vertical-align:top;" colspan="4">Tanggal / waktu pengembalian dokumen yang dipinjam: {{ row.waktu_pengembalian | moment('DD MMMM YYYY HH:mm') }}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table border="1" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 12px;line-height: 1.5;">
                          <tbody>
                            <tr>
                              <td style="padding: 4px 8px;">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_petugas_2">
                                    <span>Petugas </span> <br />
                                    <img :src="row.arfl_ttd_digital_petugas_2" alt="signature" width="200">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Petugas </div>
                                  </template>
                                  <div v-if="row.nama_petugas_2">({{ row.nama_petugas_2 }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                              <td style="padding: 4px 8px;">
                                <div style="text-align:center;">
                                  <div style="margin-bottom: 4px;">&nbsp;</div>
                                  <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital_peminjam">
                                    <span>Peminjam </span> <br />
                                    <img :src="row.arfl_ttd_digital_peminjam" alt="signature" width="200">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 52px;">Peminjam </div>
                                  </template>
                                  <div v-if="row.nama_peminjam">({{ row.nama_peminjam }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- End Copas Template -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  const moment = require('moment')
  const _ = global._

  export default {
    props: {
      id_formulir: Number,
      mrTTD: Array
    },
    components: {
      Datepicker,
      DatePicker
    },
    data(){
      return {
        loading: false,
        row: {},
      }
    },
    computed: {
      now(){
        return moment().format('DD MMM YYYY, HH:mm')
      },
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.row[field] = null
      },
      output(refs,field) {
        try{
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          console.log(isEmpty, data)
          if(!isEmpty){
            this.$set(this.row, field, data)
          }
        }catch(err){
          console.log(err)
        }
      },
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'permintaan-second-opinion',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          console.log(res)
          this.$set(this, 'row', res.data.row)

          setTimeout(() => {
            if(res.data.row.arfl_ttd_digital){
              this.$refs['ttdSecondOpinion'].fromDataURL(res.data.row.arfl_ttd_digital)
            }

            if(res.data.row.arfl_ttd_digital_dokter){
              this.$refs['ttdSecondOpinionDokter'].fromDataURL(res.data.row.arfl_ttd_digital_dokter)
            }
            
            if(res.data.row.arfl_ttd_digital_petugas){
              this.$refs['ttdSecondOpinionPetugas'].fromDataURL(res.data.row.arfl_ttd_digital_petugas)
            }

            if(res.data.row.arfl_ttd_digital_saksi){
              this.$refs['ttdSecondOpinionSaksi'].fromDataURL(res.data.row.arfl_ttd_digital_saksi)
            }
            
            if(res.data.row.arfl_ttd_digital_petugas_2){
              this.$refs['ttdSecondOpinionPetugasBawah'].fromDataURL(res.data.row.arfl_ttd_digital_petugas_2)
            }

            if(res.data.row.arfl_ttd_digital_peminjam){
              this.$refs['ttdSecondOpinionPeminjam'].fromDataURL(res.data.row.arfl_ttd_digital_peminjam)
            }
          }, 1500)


          this.$set(this, 'mRelasi', res.data.mRelasi)
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      hitSaveDraft(){
        this.row.arfl_template = document.querySelector('.document-framer').innerHTML
        this.row.id_formulir = this.id_formulir

        return Gen.apiRest(
          "/do/" + this.$route.name,
          {
            data: {
              type: 'save-draft',
              payload: this.row
            }
          }
        )
      },
      autoSave: _.debounce(function(){
        this.hitSaveDraft()
      }, 1000),
      saveDraft(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.hitSaveDraft()
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
          }
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              this.row.arfl_template = document.querySelector('.document-framer').innerHTML
              this.row.id_formulir = this.id_formulir

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    payload: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.$emit('successSubmit')
                })
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
      },
    },
    watch:{
      row: {
        handler(){
          this.autoSave()
        },
        deep: true
      }
    }
  }
</script>