<template>
<div class="card">
  <validation-observer ref="VFormSuratRujukan" v-slot="{handleSubmit}">
    <b-form @submit.prevent="handleSubmit(doSubmit)">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Transfer Antar Rumah Sakit</h6>
        </div>
      </div>
      <div class="card-body p-3">
        <div class="form-underlying card-body p-3">
          <div class="row">
            <div class="col-md-4 col-xl-3">
              <div class="form-group">
                <label for="dnrDibuatAtas">Dokter yang merujuk</label>
                <p> <b>{{row.dokter_name||"-"}}</b> </p>
              </div>
            </div>

            <div class="col-md-5 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Faskes Tujuan <span class="text-danger">*</span> </label>
                <b-form-input type="text" v-model="row.faskes_rujuk_sisrute" placeholder="Nama Faskes Rujukan" />

                <!-- <v-select class="diagnosa-section select-paging" placeholder="Cari Nama Faskes" v-model="row.faskes_rujuk_sisrute" @input="inpMasterFaskesSisrute($event)" @search="getMasterFaskesSisrute($event)" :options="mFaskesSisrute" label="NAMA" value="KODE" :clearable="true" :reduce="v=>v.KODE">
                  <template slot="no-options">
                    <span></span>
                  </template>
                </v-select> -->

                <!-- <VValidate :name="'Faskes'" v-model="row.aurujd_faskes_rujuk_sisrute_text" :rules="{required : 1}" /> -->
              </div>
            </div>

            <div class="col-md-3 col-xl-3">
              <div class="form-group">
                <label>Alasan merujuk</label>
                <!-- <p> <b>{{getConfigDynamic(Config.mr.alasanRujuk, row.aupd_alasan_rujuk)||"-"}}</b> </p> -->
                <p>{{row.alasan_merujuk||"-"}}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-xl-3">
              <div class="form-group">
                <label>Diagnosa Medis</label>
                <p>{{(row.diagnosa_primer||{}).text||"-"}}</p>
              </div>
            </div>

            <div class="col-md-3 col-xl-3">
              <div class="form-group">
                <label>Kondisi Pasien Saat Dipindahkan</label>
                <b-form-textarea v-model="row.kondisi_pasien_dipindahkan" name="" id="" rows="3" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-xl-12">
              <div class="form-group">
                <label>Tanda Tanda Vital</label>

                <div class="">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tekanan Darah</h4>
                            <p>{{row.sistolik||"-"}}/{{row.diastolik||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Nadi</h4>
                            <p>{{row.nadi||"-"}}x permenit</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Suhu</h4>
                            <p>{{row.suhu||"-"}} C</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>SPO2</h4>
                            <p>{{row.sp_o2||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Tingkat Kesadaran</h4>
                            <p>{{row.gcs_total||"-"}}
                              <span v-if="row.gcs_total >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                              <span v-else-if="row.gcs_total >= 12 && row.gcs_total <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                              <span v-else-if="row.gcs_total >= 10 && row.gcs_total <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                              <span v-else-if="row.gcs_total >= 7 && row.gcs_total <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                              <span v-else-if="row.gcs_total >= 5 && row.gcs_total <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else-if="row.gcs_total >= 4 && row.gcs_total <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                              <span v-else-if="row.gcs_total <= 4 && (row.gcs_response_mata !== null || row.gcs_response_motorik !== null || row.gcs_response_verbal !== null)" class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                              <span class="font-weight-semibold border-left ml-2" v-else> - </span>

                              <span style="margin-right:20px;">E:
                                {{row.gcs_response_mata||"-"}}
                              </span>
                              <span style="margin-right:20px;">M:
                                {{row.gcs_response_motorik||"-"}}
                              </span>
                              <span style="margin-right:20px;">V:
                                {{row.gcs_response_verbal||"-"}}
                              </span>
                              <br />(GCS)</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Respiration Rate</h4>
                            <p>{{row.pernafasan||"-"}}x Permenit</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Reflek Pupil </h4>
                            <b-form-textarea v-model="row.reflek_pupil" name="" id="" rows="3" class="form-control" />
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Reflek Cahaya </h4>
                            <b-form-textarea v-model="row.reflek_cahaya" name="" id="" rows="3" class="form-control" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>                  

          <div class="row">
            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Alergi</label>
                <b-form-textarea v-model="row.alergi" name="" id="" rows="3" class="form-control" />
              </div>
            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Riwayat Penyakit</label>
                <b-form-textarea v-model="row.riwayat_penyakit" name="" id="" rows="3" class="form-control" />
              </div>
            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Tindakan yang telah dilakukan</label>
                <b-form-textarea v-model="row.tindakan" name="" id="" rows="3" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Pemeriksaan yang telah dilakukan</label>
                <b-form-textarea v-model="row.pemeriksaan" name="" id="" rows="3" class="form-control" />
              </div>
            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Pengobatan yang telah diberikan</label>
                <b-form-textarea v-model="row.pengobatan" name="" id="" rows="3" class="form-control" />
              </div>
            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label for="dnrDibuatAtas">Perawatan pasien yang dibutuhkan</label>
                <b-form-textarea v-model="row.perawatan" name="" id="" rows="3" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label>Peralatan Medis yang Terpasang<small class="txt_mandatory">*</small></label>
                <b-form-radio-group :options="$parent.Config.mr.yesNoOptV2" v-model="row.peralatan_yang_terpasang" />

                <VValidate :name="'Peralatan Medis yang Terpasang'" v-model="row.peralatan_yang_terpasang" :rules="{required : 1}" />
              </div>

              <div class="form-group mt-2" v-if="row.peralatan_yang_terpasang == 'Y'">
                <label>Pilih Medis yang Terpasang<small class="txt_mandatory">*</small></label>
                <v-select multiple class="vs__multiple" placeholder="Pilih Media Terpasang" v-model="row.medis_terpasang" :options="$parent.Config.mr.mediaTerpasang" label="text" :reduce="v=>v.value">
                </v-select>

                <VValidate :name="'Pilih Media Terpasang'" v-model="row.medis_terpasang" :rules="{required : 1}" />
              </div>

            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label>Persetujuan Resiko Jalan<small class="txt_mandatory">*</small></label>
                <b-form-radio-group :options="$parent.Config.mr.yesNoOptV2" v-model="row.persetujuan_risiko_jalan" />

                <VValidate :name="'Persetujuan Resiko Jalan'" v-model="row.persetujuan_risiko_jalan" :rules="{required : 1}" />
              </div>
            </div>

            <div class="col-md-4 col-xl-4">
              <div class="form-group">
                <label>Kejadian Selama Transfer<small class="txt_mandatory">*</small></label>
                <b-form-radio-group :options="$parent.Config.mr.yesNoOptV2" v-model="row.kejadian_transfer" />

                <VValidate :name="'Kejiadian Selama Transfer'" v-model="row.kejadian_transfer" :rules="{required : 1}" />
              </div>

              <div class="form-group mt-2" v-if="row.kejadian_transfer == 'Y'">
                <b-form-textarea type="text" v-model="row.kejadian_transfer_text" class="form-control form-control-sm d-block" placeholder="Kejadian Transfer" />
                <VValidate :name="'Kejadian Transfer'" v-model="row.kejadian_transfer_text" :rules="{required : 1}" />
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="card-footer">
        <b-row class="justify-content-end">
          <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
            <span>Kembali</span>
          </b-button>
          <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
          <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
            <b><i class="icon-paperplane"></i></b>
            <span>Kirimkan</span>
          </b-button>
        </b-row>
      </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import Gen from '@/libs/Gen.js'
// import Datepicker from 'vuejs-datepicker'
const _ = global._
const moment = require('moment')

export default {
  // components: {
  //   Datepicker
  // },
  data() {
    return {
      loading: false,
      row: {
        diagnosa_primer: {}
      },

      mFaskesSisrute: [],
    }
  },
  computed: {
    disableDateEnd() {
      return {
        to: new Date(this.row.mulai_tanggal)
      }
    },
    disableDateFrom() {
      return {
        from: new Date(this.row.sampai_tanggal)
      }
    },
    now() {
      return moment().format('dddd DD MMMM, YYYY HH:mm')
    },
    globalComp() {
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.GetInitRow()

    setTimeout(() => {
      this.getMasterFaskesSisrute(this.row.faskes_rujuk_sisrute || "A")
    }, 1000)
  },
  methods: {
    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getMasterFaskesSisrute: _.debounce(function (e) {
      if (e) {
        let data = {
          search: e,
          type: 'select-faskes-sisrute'
        }
        Gen.apiRest(
          "/do/" + 'UGDFormDokter', {
            data: data
          }, "POST"
        ).then(res => {
          this.mFaskesSisrute = res.data
        })
      }
    }, 1000),
    inpMasterFaskesSisrute(e) {
      if (e) {
        let idx = this.mFaskesSisrute.findIndex(x => x.KODE == e)
        this.row.faskes_rujuk_sisrute = this.mFaskesSisrute[idx]['KODE']
        this.row.faskes_rujuk_sisrute_text = this.mFaskesSisrute[idx]['NAMA']
      }
    },

    hitSaveDraft() {
      return Gen.apiRest(
        "/do/" + this.$route.name, {
          data: {
            type: 'save-draft-form',
            payload: this.row,
            id_registrasi: this.$route.params.pageSlug,
            id: this.$route.params.id
          }
        }
      )
    },
    saveDraft() {
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if (result.value) {
          this.$emit('setLoadingOverlay', true)

          this.hitSaveDraft().then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$router.push({
                  name: this.$route.name,
                  params: {
                    pageSlug: this.$route.params.pageSlug
                  }
                }).catch()
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
        }
      })
    },
    autoSave: _.debounce(function () {
      this.hitSaveDraft()
    }, 1000),
    doSubmit() {
      this.$refs.VFormSuratRujukan.validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
              }, 250)
            }
          })
        }

        this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if (result.value) {
              this.$parent.loadingOverlay = true

              Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                  data: {
                    type: 'submit-dokumen',
                    type_dokumen: this.$route.params.type,
                    id: this.$route.params.id,
                    id_registrasi: this.$route.params.pageSlug,
                    status: 'UNGGAH_TTD',
                    payload: this.row
                  }
                },
                "POST"
              ).then(res => {
                this.$parent.loadingOverlay = false

                this.$swal({
                  title: res.data.message,
                  icon: 'success',
                }).then(() => {
                  this.$router.push({
                    name: this.$route.name,
                    params: {
                      pageSlug: this.$route.params.pageSlug
                    }
                  }).catch()
                })
              }).catch(err => {
                console.log(err)

                this.$parent.loadingOverlay = false
                this.$swal({
                  text: err.response.data.message || 'Terjadi suatu kesalahan!',
                  icon: 'error'
                })
              })

            }
          })

      })
    },
    GetInitRow() {
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-doc-form", {
          params: {
            id: this.$route.params.id,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false

        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    }
  },
  watch: {
    row: {
      handler() {
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>
