<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
              <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
            </b-form-group>
          </div>
        </div>
        <div :class="row.arfl_ttd == 'digital' ? 'row' : 'd-none'">
          <div class="col-md-4">
            <div class="form-group">
              <label>Nama & Tanda Tangan Dokter Rumah Sakit Harapan <span class="text-danger">*</span></label>
              <b-form-input v-model="row.ttd_name"></b-form-input>
              <VValidate v-if="row.arfl_ttd == 'digital'" name="Nama Penanda Tangan" v-model="row.ttd_name" rules="required"></VValidate>
            </div>
            <div class="form-group">
              <label for="">Tanda Tangan Petugas <span class="text-danger">*</span></label>
              <div class="signing-element" data-target-id="ttdSuratKematian">
                <VueSignaturePad  
                  class="sign-container"
                  width="100%"
                  height="160px"
                  ref="ttdSuratKematian"
                />
                <VValidate v-if="row.arfl_ttd == 'digital'" name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdSuratKematian','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button @click.prevent="output('ttdSuratKematian','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="document-framer">
              <div class="df-content">
                <div style="position: relative;max-width: 720px;">
                  <!-- Copas Template dari sini -->
                  <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                    <div>
                      <table style="border-collapse: collapse;width: 100%;font-size: 12px;">
                        <tbody>
                          <tr>
                            <td colspan="6" width="100%" style="padding:0 12px 8px;">
                              <div style="text-align:center; width: 100%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                                <h1 style="text-transform: uppercase;margin:0;font-size: 12px;line-height: 1.5;margin-bottom: 2px;">Rumah Sakit
                                  Harapan Magelang</h1>
                                <p style="margin:0;font-weight: bold;font-size: 12px;">Jl.P Senopati No 11, Magelang 56123</p>
                                <p style="margin:0;font-weight: bold;font-size: 12px;">Telp. (0293)-364033 s/d 364035 Fax. (0293-364037)</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td colspan="3" style="padding: 4px 8px;border: 1px solid #000;" width="50%">
                              <div style="text-align: center;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 20px;margin-top: 8px;">Sebab Kematian </h2>
                              </div>
                            </td>
                            <td colspan="3" style="padding: 4px 8px;border: 1px solid #000;" width="50%">
                              <div style="text-transform: uppercase;">
                                <div style="margin-bottom:4px;">Nama: {{row.fullname}}</div>
                                <div style="margin-bottom:4px;">No. RM: {{row.no_rm}}</div>
                                <div style="margin-bottom:4px;">Tgl. Lahir: {{row.dob | moment("DD MMMM YYYY")}}</div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="row.opt_1 == 'Y'" style="border-left: 1px solid #000;border-right: 1px solid #000;">
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">a.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">Penyakit atau kesadaran yang langsung mengakibatkan kematian</span>
                              </div>
                            </td>
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">a.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;"><span> {{ row.nama_penyakit_1 }}.</span> Penyakit tsb. Dalam ruang a disebabkan oleh (atau akibat dari)</span>
                              </div>
                            </td>
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">a.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;"><span> Lamanya (kira-kira) mulai sakit hingga meninggal dunia.</span> {{ row.lama_penyakit_1 }}</span>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="row.opt_2 == 'Y'" style="border-left: 1px solid #000;border-right: 1px solid #000; border-bottom: 1px solid #000;">
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">b.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">Penyakit-penyakit (bila ada) yang menjadi lantaran timbulnya sebab kematian tsb. Pada a, dengan menyebut penyakit yang menjadi pokok pangkal terakhir.</span>
                              </div>
                            </td>
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">b.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;"><span> {{ row.nama_penyakit_2 }}.</span> Penyakit tsb. Dalam ruang b disebabkan oleh (atau akibat dari)</span>
                              </div>
                            </td>
                            <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                              <div style="padding-left: 12px;">
                                <span style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;">b.</span>
                                <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;"><span> Lamanya (kira-kira) mulai sakit hingga meninggal dunia.</span> {{ row.lama_penyakit_2 }}</span>
                              </div>
                            </td>
                          </tr>
                          
                          <tr v-if="row.opt_rudapaksa == 'Y' || row.opt_kelahiran_mati == 'Y' || row.opt_persalinan_kehamilan == 'Y' || row.opt_operasi" style="border-left: 1px solid;border-right: 1px solid;">
                            <td width="33.33333%" colspan="2" style="vertical-align: top;padding: 4px 8px;">
                              <p>Keterangan Khusus Untuk:</p>
                            </td>
                            <td width="33.33333%" colspan="2" style="border-left: 1px solid;border-right: 1px solid;"></td>
                            <td width="33.33333%" colspan="2" style="border-left: 1px solid;border-right: 1px solid;"></td>
                          </tr>

                          <template v-if="row.opt_rudapaksa == 'Y'">
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <div style="padding-left: 12px;"> 
                                  <span class="romawi" style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;"></span>
                                  <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">MATI KARENA RUDAPAKSA (Violent death)</span>
                                </div>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                            <tr>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a">
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Macam Rudapaksa</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Cara kejadian rudapaksa</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Sifat jejas (kerusakan tubuh)</li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a" style="padding-left: 20px;">
                                  <li style="margin-bottom: 5px; line-height: 1.5;">{{row.jenis_rudapaksa}}</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.cara_kejadian_rudapaksa }}</span></li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.sifat_jejas_rudapaksa }}</span></li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                          </template>

                          <template v-if="row.opt_kelahiran_mati == 'Y'">
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <div style="padding-left: 12px;">
                                  <span class="romawi" style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;"></span>
                                  <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">KELAHIRAN MATI (Still birth)</span>
                                </div>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                            <tr>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a">
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Apakah ini janin mati</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Sebab kelahiran mati</li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a" style="padding-left: 20px;">
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.janin_mati == 'Y' ? 'Ya' : 'Tidak' }}</span></li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ (row.janin_mati == 'Y' && row.janin_mati_desc) ? row.janin_mati_desc : '-' }}</span></li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                          </template>

                          <template v-if="row.opt_persalinan_kehamilan == 'Y'">
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <div style="padding-left: 12px;">
                                  <span class="romawi" style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;"></span>
                                  <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">PERSALINAN KEHAMILAN</span>
                                </div>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a">
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Apakah ini peristiwa persalinan</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Apakah ini peristiwa kehamilan</li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a" style="padding-left: 20px;">
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.peristiwa_persalinan == 'Y' ? 'Ya' : 'Tidak' }} {{ row.peristiwa_persalinan == 'Y' && row.peristiwa_persalinan_desc ? ', ' + row.peristiwa_persalinan_desc : ''}}</span></li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.peristiwa_kehamilan == 'Y' ? 'Ya' : 'Tidak' }} {{ row.peristiwa_kehamilan == 'Y' && row.peristiwa_kehamilan_desc ? ', ' + row.peristiwa_kehamilan_desc : '-'}}</span></li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                          </template>

                          <template v-if="row.opt_operasi">
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <div style="padding-left: 12px;">
                                  <span class="romawi" style="display: inline-block;width: 7%;box-sizing: border-box;padding-right: 5px;vertical-align: top;"></span>
                                  <span style="display: inline-block;width: 90%;box-sizing: border-box;vertical-align: top;">OPERASI</span>
                                </div>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                            <tr style="border-left: 1px solid;border-right: 1px solid;">
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a" style="margin: 4px 0;">
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Apakah disini dilakukan operasi</li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;">Jenis operasi</li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;">
                                <ol type="a" style="padding-left: 20px;">
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ row.operasi == 'Y' ? 'Ya' : 'Tidak'}}</span></li>
                                  <li style="margin-bottom: 5px; line-height: 1.5;"><span>{{ (row.operasi == 'Y' && row.operasi_desc) ? row.operasi_desc : '-' }}</span></li>
                                </ol>
                              </td>
                              <td width="33.3333%" colspan="2" style="vertical-align: top;padding: 4px 8px;border-left: 1px solid;border-right: 1px solid;"></td>
                            </tr>
                          </template>
                        </tbody>
                        <tbody>
                          <tr>
                            <td width="100%" colspan="6" style="vertical-align: top;padding: 10px; text-align: right;border: 1px solid #000;">
                              <div style="margin-bottom: 0px;">Magelang, {{ now }}</div>
                              <div :style="'margin-bottom:' + (row.arfl_ttd_digital ? '0px' : '48px')">Yang memberi keterangan sebab kematian</div>
                              <span v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                <img :src="row.arfl_ttd_digital" alt="signature" width="200" id="ttdPasien">
                                <br />
                              </span>
                              <div v-if="row.arfl_ttd == 'digital'">({{ row.ttd_name }})</div>
                              <div v-else>(...............................................)</div>
                              <p v-if="row.arfl_ttd != 'digital'">Mohon nama terang dan tanda tangan</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- End Copas Template -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
const moment = require('moment')
const _ = global._

export default {
  props: {
    id_formulir: Number,
    mrTTD: Array
  },
  data(){
    return {
      loading: false,
      row: {},
      romawi: {
        1: 'I',
        2: 'II',
        3: 'III',
        4: 'IV',
        5: 'V',
        6: 'VI',
        7: 'VII',
        8: 'VIII'
      }
    }
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY, HH:mm')
    },
  },
  mounted(){
    this.GetPasienData()
  },
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        console.log(isEmpty, data)
        if(!isEmpty){
          this.$set(this.row, field, data)
        }
      }catch(err){
        console.log(err)
      }
    },
    GetPasienData(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$route.name + '/get-data',
        {
          params: {
            type: 'surat-keterangan-penyebab-kematian',
            id_formulir: this.id_formulir,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        this.$set(this, 'row', res.data.row)

        setTimeout(() => {
          if(res.data.row.arfl_ttd_digital){
            this.$refs['ttdSuratKematian'].fromDataURL(res.data.row.arfl_ttd_digital)
          }
        }, 1500)

        setTimeout(() => {
          document.querySelectorAll('.romawi').forEach((v, i) => {
            v.innerHTML = this.romawi[i + 1] + '.'
          })
        }, 200)
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    hitSaveDraft(){
      this.row.arfl_template = document.querySelector('.document-framer').innerHTML
      this.row.id_formulir = this.id_formulir

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft',
            payload: this.row
          }
        }
      )
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          this.hitSaveDraft()
          .then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$emit('successSubmit')
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    doSubmit(){
      this.$refs.VFormTTD.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            if(this.row.arfl_ttd == 'manual'){
              this.row.ttd_name = ''
              this.row.arfl_ttd_digital = ''
            }

            this.row.arfl_template = document.querySelector('.document-framer').innerHTML
            this.row.id_formulir = this.id_formulir

            Gen.apiRest(
              "/do/" + this.$route.name,
              {
                data: {
                  type: 'submit-ttd',
                  payload: this.row
                }
              }
            )
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>

