<template>
  <div>
    <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                <b-form-radio-group
                  id="ttd"
                  v-model="row.arfl_ttd"
                  :options="mrTTD"
                  name="ttd"
                ></b-form-radio-group>
                <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
              </b-form-group>
            </div>
          </div>
          <b-alert v-if="row.arfl_ttd == 'manual'" show variant="info"><i class="icon-info22"></i> Simpan pengisian dokumen ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</b-alert>
          <div class="row" v-if="row.arfl_ttd">
            <div class="col-md-4">
              <div class="form-group">
                <label>Nama & Tanda Tangan Dokter <span class="text-danger">*</span></label>
                <b-form-input v-model="row.nama_dokter"></b-form-input>
                <VValidate name="Nama Dokter" v-model="row.nama_dokter" rules="required"></VValidate>
              </div>
              <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                <div class="signing-element" data-target-id="ttdSuratKeteranganOpname">
                  <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdSuratKeteranganOpname"
                  />
                  <VValidate v-if="row.arfl_ttd == 'digital'" name="Tanda Tangan" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                  <div class="mt-2">
                  <button type="button" @click="undoTTD('ttdSuratKeteranganOpname','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                  </button>
                  
                  <button @click.prevent="output('ttdSuratKeteranganOpname','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="document-framer">
                <div style="height: 100%; position: relative; font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif; color: rgb(51, 51, 51); line-height: 1.5; font-size: 13px;">
                  <div>
                    <table border="1" style="border-collapse: collapse; width: 100%;">
                      <tbody>
                        <tr>
                          <td style="padding: 0px;">
                            <table border="0" style="border-collapse: collapse; width: 100%;">
                              <tbody>
                                <tr>
                                  <td style="padding: 8px 12px;">
                                    <div style="float: left; width: 12%; vertical-align: middle; box-sizing: border-box; padding-right: 8px;">
                                      <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                    </div>
                                    <div style="text-transform: uppercase; float: left; width: 88%; vertical-align: middle; box-sizing: border-box; padding-left: 8px; padding-right: 12%; text-align: center;">
                                      <h1 style="margin: 0px 0px 2px; font-size: 18px; line-height: 20px;">Rumah Sakit Harapan Magelang</h1>
                                      <p style="margin: 0px; font-size: 13px;">Jl. P. Senopati No. 11 Magelang 56123 <br>Telp. (0293)364033 s/d 364035 Fax. (0293)364037 </p>
                                    </div>
                                    <div style="display: block; clear: both;"></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 0px;">
                            <table border="0" style="border-collapse: collapse; width: 100%;">
                              <tbody>
                                <tr>
                                  <td colspan="2" style="padding: 12px 24px;">
                                    <h3 style="font-weight: bold; text-transform: uppercase; letter-spacing: 6px; text-decoration: underline; text-align: center; font-size: 20px; margin: 12px 0px 0px;">Surat Keterangan</h3>
                                    <p style="text-align: center;font-size: 18px">{{ row.no_surat }}</p>
                                    <div style="font-size: 16px; line-height: 26px;">
                                      <p style="margin: 8px 0px;">Yang bertanda tangan dibawah ini menerangkan bahwa:</p>
                                      <table style="width: 100%; margin: 0px;">
                                        <tbody>
                                          <tr>
                                            <td width="72">Nama</td>
                                            <td width="20" style="text-align: center;">:</td>
                                            <td style="border-bottom: 1px solid;">{{row.fullname}}</td>
                                          </tr>
                                          <tr>
                                            <td width="72">Umur</td>
                                            <td width="20" style="text-align: center;">:</td>
                                            <td style="border-bottom: 1px solid;">{{row.usia}} Tahun</td>
                                          </tr>
                                          <tr>
                                            <td width="72">Alamat</td>
                                            <td width="20" style="text-align: center;">:</td>
                                            <td style="border-bottom: 1px solid;">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p style="margin: 8px 0px;">Oleh karena <strong style="text-transform: uppercase;">
                                          <span :style="'text-decoration: ' + ((row.alasan_izin || '').toLowerCase() == 'hamil' ? 'line-through' : '')">Sakit</span> 
                                          / 
                                          <span :style="'text-decoration: ' + ((row.alasan_izin || '').toLowerCase() == 'sakit' ? 'line-through' : '')">Hamil</span>

                                        </strong>, perlu diberikan <strong style="text-transform: uppercase;">
                                          <span :style="'text-decoration: ' + ((row.keperluan_izin || '').toLowerCase() == 'cuti hamil' ? 'line-through' : '')">Istirahat</span> 
                                          /
                                          <span :style="'text-decoration: ' + ((row.keperluan_izin || '').toLowerCase() == 'istirahat' ? 'line-through' : '')">Cuti Hamil</span>
                                        </strong>. </p>
                                      <p style="margin: 8px 0px;">Selama <strong style="margin: 0px 4px; width: 120px; display: inline-block; border-bottom: 1px solid rgb(0, 0, 0);">{{countDays(row.tanggal_awal, row.tanggal_akhir)}}</strong> hari terhitung mulai tanggal <strong style="margin: 0px 4px; width: 160px; display: inline-block; border-bottom: 1px solid rgb(0, 0, 0);">{{row.tanggal_awal | moment('DD MMMM YYYY')}}</strong> s/d <strong style="margin: 0px 4px; width: 160px; display: inline-block; border-bottom: 1px solid rgb(0, 0, 0);">{{ row.tanggal_akhir | moment('DD MMMM YYYY') }}</strong>
                                      </p>
                                      <p style="margin-top: 8px;">Demikianlah surat keterangan ini dibuat sebenar-benarnya dan untuk dipergunakan semestinya.</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="50%" style="vertical-align: top;"></td>
                                  <td style="vertical-align: top;">
                                    <table border="0" style="width: 100%; text-align: center; margin-bottom: 8px; font-size: 14px;">
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td>
                                            <span>Magelang, {{now}}</span>
                                            <br>
                                            <span>Dokter Pemeriksa,</span>
                                            <br>
                                            <span v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                              <img :src="row.arfl_ttd_digital" alt="signature" width="200" id="ttdPasien">
                                              <br/>
                                            </span>
                                            <span v-else>
                                              <br><br><br><br><br>
                                            </span>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td style="border-bottom: 1px solid;"> {{ row.nama_dokter }} </td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-row class="mt-4 text-center">
            <b-col class="col-md-12">
              <b-button type="button" variant="secondary" @click="$emit('closeModal')">Kembali</b-button>
              <b-button type="button" class="ml-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button variant="primary" class="ml-2" type="submit">Submit <i class="icon-paperplane ml-2"></i></b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')
  const _ = global._

  export default {
    props: {
      id_formulir: Number,
      mrTTD: Array
    },
    data(){
      return {
        loading: false,
        row: {},
      }
    },
    computed: {
      now(){
        return moment().format('DD MMM YYYY')
      },
    },
    mounted(){
      this.GetPasienData()
    },
    methods: {
      countDays(date1, date2) {

        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const firstDate = new Date(date1)
        firstDate.setHours(0)
        const secondDate = new Date(date2)
        secondDate.setHours(0)
        return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1
      },
      assetLocal(img){
        let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
        img = def+img
        return img
      },
      undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.row[field] = null
      },
      output(refs,field) {
        try{
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          console.log(isEmpty, data)
          if(!isEmpty){
            this.$set(this.row, field, data)
          }
        }catch(err){
          console.log(err)
        }
      },
      GetPasienData(){
        this.loading = true

        Gen.apiRest(
          "/get/" + this.$route.name + '/get-data',
          {
            params: {
              type: 'surat-keterangan-opname',
              id_formulir: this.id_formulir,
              id_registrasi: this.$route.params.pageSlug
            }
          }
        ).then(res => {
          this.loading = false
          this.$set(this, 'row', res.data.row)

          setTimeout(() => {
            if(res.data.row.arfl_ttd_digital){
              this.$refs['ttdSuratKeteranganOpname'].fromDataURL(res.data.row.arfl_ttd_digital)
            }
          }, 1500)

        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      hitSaveDraft(){
        this.row.arfl_template = document.querySelector('.document-framer').innerHTML
        this.row.id_formulir = this.id_formulir

        return Gen.apiRest(
          "/do/" + this.$route.name,
          {
            data: {
              type: 'save-draft',
              payload: this.row
            }
          }
        )
      },
      autoSave: _.debounce(function(){
        this.hitSaveDraft()
      }, 1000),
      saveDraft(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.hitSaveDraft()
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$emit('successSubmit')
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })
          }
        })
      },
      doSubmit(){
        this.$refs.VFormTTD.validate().then(success => {
          if(!success) return

          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          })
          .then(result => {
            if(result.value){
              this.$emit('setLoadingOverlay', true)

              this.row.arfl_template = document.querySelector('.document-framer').innerHTML
              this.row.id_formulir = this.id_formulir

              Gen.apiRest(
                "/do/" + this.$route.name,
                {
                  data: {
                    type: 'submit-ttd',
                    payload: this.row
                  }
                }
              )
              .then(res => {
                this.$emit('setLoadingOverlay', false)
                this.$swal({
                  icon: 'success',
                  text: res.data.message,
                }).then(() => {
                  this.$emit('successSubmit')
                })
              })
              .catch(err => {
                this.$emit('setLoadingOverlay', false)
                console.log(err)
                this.$swal({
                  icon: 'error',
                  text: 'Terjadi suatu kesalahan!'
                })
              })

            }
          })

        })
      },
    },
    watch:{
      row: {
        handler(){
          this.autoSave()
        },
        deep: true
      }
    }
  }
</script>