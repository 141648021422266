<template>
  <div class="card">
    <validation-observer ref="VFormSuratKeteranganLahir" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <div class="card-header bg_head_panel">
          <div class="row">
            <div class="col-md-4">
              <h6 class="card-title font-weight-semibold">Form Surat Keterangan Lahir</h6>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <b-overlay :show="loading">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>Penandatanganan Dokumen <span class="text-danger">*</span></label>
                  <b-form-radio-group
                    id="ttd"
                    v-model="row.arfl_ttd"
                    :options="mrTTD"
                    name="ttd"
                  ></b-form-radio-group>
                  <VValidate name="Penandatanganan Dokumen" rules="required" v-model="row.arfl_ttd"></VValidate>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="row.arfl_ttd">
              <b-col md="4">
                <b-form-group>
                  <label>Nama Penolong <span class="text-danger">*</span></label>
                  <b-form-input v-model="row.ttd_name"></b-form-input>
                  <VValidate name="Nama Penolong" v-model="row.ttd_name" rules="required" />
                </b-form-group>
                <div class="form-group" v-if="row.arfl_ttd == 'digital'">
                  <label>Tanda Tangan Penolong <span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdPenolong">
                    <VueSignaturePad  
                      class="sign-container"
                      width="100%"
                      height="160px"
                      ref="ttdPenolong"
                    />
                    <VValidate name="Tanda Tangan Penolong" v-model="row.arfl_ttd_digital" rules="required"></VValidate>
                    <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPenolong','arfl_ttd_digital')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                      </button>
                      <button @click.prevent="output('ttdPenolong','arfl_ttd_digital')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <div class="document-framer">
                  <div class="df-content">
                    <div style="position: relative;max-width: 500px;">
                      <!-- Copas Template dari sini -->
                      <div
                        style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 11px;">
                        <div>
                          <table style="border-collapse: collapse;width: 100%;font-size: 11px;line-height: 1.5;" border="1">
                            <tr>
                              <td style="padding: 8px;">
                                <div
                                  style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;">
                                  <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;width: 56px;" alt="">
                                </div>
                                <div
                                  style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding: 0 20px;">
                                  <h1 style="margin:0;font-size: 1.33em;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan Magelang</h1>
                                  <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                                  <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                                  <p style="margin:0;">Fax. (0293-364037)</p>
                                </div>
                              </td>
                              <td colspan=2 style="padding: 8px; vertical-align: top;">
                                <div style="text-align: center;">
                                  <h2 style="margin: 0;text-transform: uppercase;font-size: 1.25em;">
                                    <span style="font-size: 0.8em;">Surat Keterangan</span>
                                    <span style="display: block;">Kelahiran</span>
                                  </h2>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" style="padding: 16px">
                                <p>Telah Lahir di Rumah Sakit Harapan</p>
                                <div>
                                  <table border="0" style="border-collapse: collapse;font-size: 11px;line-height: 1.5;">
                                    <tr>
                                      <td width="25%" style="padding: 2px 8px;vertical-align: top;">Nama Ibu</td>
                                      <td width="3%" style="vertical-align: top;">:</td>
                                      <td width="70%" style="padding: 2px 8px;vertical-align: top;">{{row.nama_ibu}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Nama Ayah</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.nama_ayah}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Pada Tanggal</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.dob_kajian_awal | moment('dddd, DD MMMM YYYY')}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Jam</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.tob_kajian_awal}} WIB</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Jenis Kelamin</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.gender == 1 ? 'Laki-Laki' : row.gender == 2 ? 'Perempuan' : 'Tidak Diketahui'}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Berat Badan</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.berat_badan}} gram</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Panjang Badan</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.tinggi_badan}}cm</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Alamat</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;" width="88%">{{ row.address }}, {{ row.rt ? 'RT ' + row.rt : '' }} {{ row.rw ? '/' + row.rw : '' }} {{ row.kelurahan ? '. Kel. ' + row.kelurahan : '' }} {{ row.kecamatan ? '. Kec. ' + row.kecamatan : '' }}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 2px 8px;vertical-align: top;">Nama Bayi</td>
                                      <td style="vertical-align: top;">:</td>
                                      <td style="padding: 2px 8px;vertical-align: top;">{{row.fullname}}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div style="margin-top: 8px;">
                                  <table border="0" width="100%" style="font-size: 11px;line-height: 1.5;">
                                    <tr>
                                      <td style="vertical-align: bottom;">
                                        <span>Keterangan:</span>
                                        <ol style="margin: 0;padding-left: 12px;">
                                          <li>Lb. Putih (Pasien)</li>
                                          <li>Lb. Merah (Rekam Medis)</li>
                                          <li>Lb. Hijau (Administrasi Ruangan)</li>
                                        </ol>
                                      </td>
                                      <td>
                                        <template v-if="row.arfl_ttd == 'digital' && row.arfl_ttd_digital">
                                          <span>Magelang, {{ row.dob_kajian_awal | moment('DD MMMM YYYY') }}<br />Yang Menolong</span><br />
                                          <img :src="row.arfl_ttd_digital" alt="signature" width="150" id="ttdPasien">
                                          <br />
                                        </template>
                                        <template v-else>
                                          <div style="margin-bottom: 48px;">Magelang, {{ row.dob_kajian_awal | moment('DD MMMM YYYY') }}<br />Yang Menolong</div>
                                        </template>
                                        <div v-if="row.ttd_name">({{ row.ttd_name }})</div>
                                        <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- End Copas Template -->
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-end mt-3">
              <b-button type="button" variant="secondary" class="mr-2" @click.prevent="$router.back()">
                <span>Kembali</span>
              </b-button>
              <b-button type="button" class="mr-2" variant="warning" @click="saveDraft">Simpan Sementara</b-button>
              <b-button type="submit" variant="primary" class="btn-labeled btn-labeled-left">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </b-button>
            </b-row>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'

const _ = global._
export default {
  props: {
    mrTTD: Array
  },
  data(){
    return { 
      loading: false,
      row: {}
    }
  },
  mounted(){
    this.GetInitRow()
  },
  methods: {
    GetInitRow(){
      this.loading = true

      Gen.apiRest(
        "/get/" + this.$parent.modulePage + "/get-data", 
        {
          params: {
            type: this.$route.params.type,
            id_formulir: this.$route.params.id,
            id_registrasi: this.$route.params.pageSlug
          }
        }
      ).then(res => {
        this.loading = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        setTimeout(() => {
          if(res.data.row.arfl_ttd_digital){
            this.$refs['ttdPenolong'].fromDataURL(res.data.row.arfl_ttd_digital)
          }
        }, 1500)
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.row[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        console.log(isEmpty, data)
        if(!isEmpty){
          this.$set(this.row, field, data)
        }
      }catch(err){
        console.log(err)
      }
    },
    hitSaveDraft(){

      this.row.arfl_template = document.querySelector('.document-framer').innerHTML
      this.row.id_formulir = this.$route.params.id

      return Gen.apiRest(
        "/do/" + this.$route.name,
        {
          data: {
            type: 'save-draft',
            payload: this.row
          }
        }
      )
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
    saveDraft(){
      this.$swal({
        icon: 'warning',
        title: 'Apakah Anda Yakin ingin menyimpan sementara data ini?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          this.$emit('setLoadingOverlay', true)

          this.hitSaveDraft()
          .then(res => {
            this.$emit('setLoadingOverlay', false)
            this.$swal({
              icon: 'success',
              text: res.data.message,
            }).then(() => {
              this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
            })
          })
          .catch(err => {
            this.$emit('setLoadingOverlay', false)
            console.log(err)
            this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan!'
            })
          })
        }
      })
    },
    doSubmit(){
      this.$refs.VFormSuratKeteranganLahir.validate().then(success => {
        if(!success) return

        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        })
        .then(result => {
          if(result.value){
            this.$emit('setLoadingOverlay', true)

            this.row.arfl_template = document.querySelector('.document-framer').innerHTML
            this.row.id_formulir = this.$route.params.id
            this.row.type_doc = this.$route.params.type

            Gen.apiRest(
              "/do/" + this.$route.name,
              {
                data: {
                  type: 'submit-ttd',
                  payload: this.row
                }
              }
            )
            .then(res => {
              this.$emit('setLoadingOverlay', false)
              this.$swal({
                icon: 'success',
                text: res.data.message,
              }).then(() => {
                this.$router.push({name: this.$route.name, params: { pageSlug: this.$route.params.pageSlug }}).catch()
              })
            })
            .catch(err => {
              this.$emit('setLoadingOverlay', false)
              console.log(err)
              this.$swal({
                icon: 'error',
                text: 'Terjadi suatu kesalahan!'
              })
            })

          }
        })

      })
    },
  },
  watch:{
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>